


.trafficTagsArea {
    width: 100%;
    text-align: center;
    height: 50px;
    display: grid;
    grid-template-columns: 1fr 4fr;
    align-items: center;
}

.mobileTrafficTagsArea {
    width: 100%;
    text-align: center;
    height: 100px;
    align-items: center;
    overflow-x: scroll;
}

.headerScore {
    font-size: 28px; 
    font-family: sans-serif; 
    margin-left: 30px; 
    margin-right: 30px; 
    padding: 7px;
    color: black; 
    font-weight: bold;
    border: 4px solid darkgreen;
    border-radius: 50%;
}

.topPlacesTitle {
    position: absolute;
    z-index: 13;
    color: black;
    font-size: 12;
    top: 5%;
    left: 5%;
    font-family: sans-serif;
    font-weight: bold;
    font-style: italic;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px white;
}

.poiContent {
    padding-left: 40px;
}



.poiName {
    font-weight: bold;
    font-size: smaller;
    margin-top: 15px;
    width: 90%;
    height: 40px;
    overflow: hidden;
    overflow-y: scroll;
}

.poiDistance {
    font-family: sans-serif;
    font-size: small;
    text-align: center;
    margin-left: 20px;
    height: 30px;
}

.mobilePoiDistance {
    font-family: sans-serif;
    font-size: small;
    text-align: center;
    margin-left: 10px;
    height: 30px;
}

.poi1Icon {
    padding-left: 28px;
    padding-bottom: 10px;
}

.poi2Icon {
    padding-left: 30px;
    padding-bottom: 10px;
}

.poi3Icon {
    padding-left: 30px;
    padding-bottom: 10px;
}

.poiIcon {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}

.poi1Icon::after {
    content: '';
    position: absolute;
    background-color: purple;
    z-index: 12;
    width: 30px;
    height: 30px;
    bottom: 65%;
    left: 3%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.poi2Icon::after {
    content: '';
    position: absolute;
    background-color: purple;
    z-index: 12;
    width: 30px;
    height: 30px;
    bottom: 65%;
    left: 3%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.poi3Icon::after {
    content: '';
    position: absolute;
    background-color: purple;
    z-index: 12;
    width: 30px;
    height: 30px;
    bottom: 65%;
    left: 3%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.mobilePoi1Icon {
    padding-left: 28px;
    padding-bottom: 10px;
}

.mobilePoi2Icon {
    padding-left: 30px;
    padding-bottom: 10px;
}

.mobilePoi3Icon {
    padding-left: 30px;
    padding-bottom: 10px;
}

.mobilePoiIcon {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}

.mobilePoi1Icon::after {
    content: '';
    position: absolute;
    background-color: purple;
    z-index: 12;
    width: 30px;
    height: 30px;
    bottom: 65%;
    left: 10%;
    transform: translate(-10%, -65%);
    border-radius: 50%;
}

.mobilePoi2Icon::after {
    content: '';
    position: absolute;
    background-color: purple;
    z-index: 12;
    width: 30px;
    height: 30px;
    bottom: 65%;
    left: 10%;
    transform: translate(-10%, -65%);
    border-radius: 50%;
}

.mobilePoi3Icon::after {
    content: '';
    position: absolute;
    background-color: purple;
    z-index: 12;
    width: 30px;
    height: 30px;
    bottom: 65%;
    left: 10%;
    transform: translate(-10%, -65%);
    border-radius: 50%;
}

.markerNumber {
    position: absolute;
    z-index: 13;
    color: white;
    font-family: sans-serif;
    font-size: 12;
    bottom: 80%;
    left: 1%;
}

.mobileMarkerNumber {
    position: absolute;
    z-index: 13;
    color: white;
    font-family: sans-serif;
    font-size: 12;
    bottom: 71%;
    left: 16%;
    transform: translate(-16%, -71%);
}

.star {
    position: absolute;
    z-index: 13;
    bottom: 20%;
    left: 0%;
    width: 25px;
    height: 25px;
}

.mobileStar {
    position: absolute;
    z-index: 13;
    bottom: 50%;
    left: 3%;
    width: 20px;
    height: 20px;
}

.star1 {
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 13;
    top: 5%;
    right: 19%;
}

.mobileStar1 {
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 13;
    bottom: 4%;
    right: 28%;
    transform: translate(-28%, -4%);
}

.starDescription {
    position: absolute;
    z-index: 13;
    top: 5%;
    right: 5%;
    font-family: sans-serif;
    font-size: small;
    font-style: italic;
}

.mobileStarDescription {
    position: absolute;
    z-index: 13;
    bottom: 4%;
    right: 4%;
    transform: translate(-4%, -4%);
    font-family: sans-serif;
    font-size: small;
    font-style: italic;
}

.poweredByGoogle {
    position: absolute;
    z-index: 12;
    left: 40%;
    bottom: 83%;
    background: transparent;
}

.yelpLogoTraffic {
    position: absolute;
    z-index: 12;
    right: 3%;
    bottom: 83%;
    background: transparent;
    width: 40px;
    height: 20px;
}

.walkscoreLogoTraffic {
    position: absolute;
    z-index: 12;
    right: 3%;
    bottom: 86%;
    padding-top: 5px;
    width: 75px;
    height: 17px;
}

.staticMap {
    margin-top: 10px; 
    margin-left: 40px;
    padding-bottom: 125px;
    position: relative;
}

.staticMap::after {
    content: '';
    position: absolute;
    background-color: transparent;
    z-index: 10;
    width: 350px;
    height: 350px;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 32%);
    border-radius: 50%;
    border: 3px solid red;
}

.mobileStaticMap {
    position: relative;
    margin-left: 10px;
}

.mobileStaticMap::after {
    content: '';
    position: absolute;
    background-color: transparent;
    z-index: 10;
    width: 300px;
    height: 300px;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -53%);
    border-radius: 50%;
    border: 3px solid red;
}

.chartTitle {
    position: absolute;
    z-index: 12;
    color: black;
    background-color: white;
    border: 1px solid black;
    font-family: sans-serif;
    font-size: small;
    padding-right: 5px;
    padding-left: 5px;
    bottom: 75%;
    left: 27.5%;
}

.popularTimes {
    z-index: 12;    
    background-color: white;
    width: 420px;
    height: 180px;
    position: relative;
}

#daytimeTrafficTitle {
    position: absolute;
    z-index: 12;
    color: black;
    font-family: sans-serif;
    text-align: center;
    font-weight: bold;
    width: 75px;
    height: 40px;
    font-size: 13px;
    bottom: 75%;
    left: 20%;
}

#nightlifeTitle {
    position: absolute;
    z-index: 12;
    color: black;
    width: 75px;
    height: 40px;
    font-family: sans-serif;
    font-size: 13px;
    text-align: center;
    font-weight: bold;
    bottom: 32%;
    left: 20%;
}

.sun {
    position: absolute;
    color: orange;
    z-index: 11;
    bottom: 78%;
    left: 85%;
    width: 32px;
    height: 32px;
}

.moon {
    position: absolute;
    color: midnightblue;
    z-index: 11;
    bottom: 40%;
    left: 85%;
    width: 32px;
    height: 32px;
}

.dayTrafficGauge {
    --rotation: 90;
    position: absolute;
    bottom: 87%;
    left: 30%;
    width: 10px;
    height: 90%;
    background: linear-gradient(to top, rgb(228, 114, 103), rgb(250, 204, 68), rgb(52, 181, 122));
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}

.dayTrafficGaugePointer {
    position: absolute;
    --bottomDay: 100;
    left: 29%;
    bottom: 91%;
    transform-origin: bottom;
    z-index: 12;
    transform: translateX(calc(var(--bottomDay) * 1.5px));
    width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 12px solid black;
}

.dayTrafficGaugeHigh {
    position: absolute;
    z-index: 12;
    bottom: 76%;
    left: 88%;
    font-size: 10px;
    font-weight: bold;
    color: #058f1c;
}

.dayTrafficGaugeLow {
    position: absolute;
    z-index: 11;
    bottom: 76%;
    left: 23%;
    font-size: 10px;
    font-weight: bold;
    color: #d80808;
}

.nightlifeGauge {
    --rotation: 90;
    position: absolute;
    bottom: 50%;
    left: 30%;
    width: 10px;
    height: 90%;
    background: linear-gradient(to top, rgb(228, 114, 103), rgb(250, 204, 68), rgb(52, 181, 122));
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}

.nightlifeGaugePointer {
    position: absolute;
    --bottomNight: 100;
    left: 29%;
    bottom: 54%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomNight) * 1.5px));
    width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 12px solid black;
}

.nightlifeGaugeHigh {
    position: absolute;
    z-index: 12;
    bottom: 39%;
    left: 88%;
    font-size: 10px;
    font-weight: bold;
    color: #058f1c;
}

.nightlifeGaugeLow {
    position: absolute;
    z-index: 12;
    bottom: 39%;
    left: 23%;
    font-size: 10px;
    font-weight: bold;
    color: #d80808;
}

.busyTimesModal {
    position: absolute;
    z-index: 12;
    bottom: 3%;
    left: 38%;
}

.walkscoreData {
    z-index: 12;
    background-color: white;
    width: 420px;
    height: 160px;
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative;
}

.accessTitle {
    position: absolute;
    z-index: 12;
    color: black;
    font-family: sans-serif;
    font-weight: bold;
    text-align: center;
    font-style: italic;
    font-size: 16px;
    bottom: 72%;
    left: 3%;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px white;
}

.walkscore-columns {
    text-align: center;
    margin-top: 10px;
}

.walkscoreData .score {
    margin-top: 10px;
    font-size: larger;
    font-weight: bold;
}

.walkscoreData .description {
    font-family: sans-serif;
    font-size: small;
}

.walkscoreData .icon {
    padding-bottom: 10px;
    margin-top: 5px;
    width: 35px;
    height: 50px;
    margin-left: 30px;
}

.walkscoreData .walkIcon {
    padding-bottom: 15px;
    margin-top: 5px;
}

.walkPic {
    width: 35px;
    height: 50px;
    margin-left: 10px;
}

.mobileWalkPic {
    width: 35px;
    height: 50px;
    margin-left: 10px;
    margin-bottom: -2.5px;
}

.engagement {
    z-index: 12;
    background-color: white;
    width: 420px;
    height: 160px;
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative;
}

.engagementTitle {
    position: absolute;
    z-index: 12;
    color: black;
    font-family: sans-serif;
    font-weight: bold;
    text-align: center;
    font-style: italic;
    font-size: 16px;
    bottom: 72%;
    left: 3%;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px white;
}

.engagement .totalReviewCount {
    font-size: larger;
    font-weight: bold;
    text-align: center; 
    margin-top: 30px;
    margin-left: 5px;
}

.reviewCountGauge {
    --rotation: 90;
    position: absolute;
    bottom: 55%;
    left: 25%;
    width: 10px;
    height: 100%;
    background: linear-gradient(to top, rgb(228, 114, 103), rgb(250, 204, 68), rgb(52, 181, 122));
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}

.layoutReviewCountPointer {
    position: absolute;
    --bottomReview: 130;
    left: 24%;
    bottom: 60%;
    transform-origin: left;
    z-index: 12;
    transform: translateX(calc(var(--bottomReview) * 1px));
    width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 12px solid black;
}

.revCountHigh {
    position: absolute;
    z-index: 12;
    bottom: 40%;
    left: 82%;
    font-size: 10px;
    font-weight: bold;
    color: #058f1c;
}

.revCountLow {
    position: absolute;
    z-index: 12;
    bottom: 40%;
    left: 17%;
    font-size: 10px;
    font-weight: bold;
    color: #d80808;
}

.busyRoadTraffic {
    position: relative;
    z-index: 12;
    background-color: white;
    width: 420px;
    height: 160px;
    padding-top: 15px;
    padding-bottom: 15px;
}

#trafficBusyRoadSection {
    margin-left: 35px;
}

.roadTrafficTitle {
    position: absolute;
    z-index: 12;
    color: black;
    font-family: sans-serif;
    font-weight: bold;
    text-align: center;
    font-style: italic;
    font-size: 16px;
    bottom: 72%;
    left: 3%;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px white;
}

.busyRoadVisibilityIcon {
    position: relative
}

.orangeRoadIcon1 {
    --rotation: 0;
    position: absolute;
    bottom: 53%;
    left: 40%;
    width: 7px;
    height: 55%;
    background-color: sandybrown;
    border-left: 1px solid black;
    transform-origin: bottom;
    z-index: 11;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}

.orangeRoadIcon2 {
    --rotation: 0;
    position: absolute;
    bottom: 53%;
    left: 51%;
    width: 11px;
    height: 55%;
    background-color: sandybrown;
    border-left: 2px dashed black;
    border-right: 2px dashed black;
    transform-origin: bottom;
    z-index: 11;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}

.orangeRoadIcon3 {
    --rotation: 0;
    position: absolute;
    bottom: 53%;
    left: 62.5%;
    width: 7px;
    height: 55%;
    background-color: sandybrown;
    border-right: 1px solid black;
    transform-origin: bottom;
    z-index: 11;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}

.yellowRoadIcon1 {
    --rotation: 0;
    position: absolute;
    bottom: 53%;
    left: 45%;
    width: 11px;
    height: 55%;
    background-color: rgb(252, 255, 66);
    border-left: 1px solid black;
    border-right: 2px dashed black;
    transform-origin: bottom;
    z-index: 11;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}

.yellowRoadIcon2 {
    --rotation: 0;
    position: absolute;
    bottom: 53%;
    left: 56%;
    width: 11px;
    height: 55%;
    background-color: rgb(252, 255, 66);
    border-left: 2px dashed black;
    border-right: 1px solid black;
    transform-origin: bottom;
    z-index: 11;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}

.specialSideStreetIcon {
    --rotation: 0;
    position: absolute;
    bottom: 53%;
    left: 50%;
    width: 15px;
    height: 55%;
    background-color: lavender;
    border-left: 1px solid black;
    border-right: 1px solid black;
    transform-origin: bottom;
    z-index: 11;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}

.sideStreetIcon {
    --rotation: 0;
    position: absolute;
    bottom: 53%;
    left: 50%;
    width: 12px;
    height: 55%;
    background-color: lightgrey;
    border-left: 1px solid black;
    border-right: 1px solid black;
    transform-origin: bottom;
    z-index: 11;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}

.roadDescription {
    margin-top: 10px;
    padding-top: 35px;
    padding-left: 8px;
    font-family: sans-serif;
    font-size: x-small;
    text-align: center;
    width: 70px;
}

.roadDescription2 {
    margin-top: 10px;
    padding-top: 35px;
    font-family: sans-serif;
    font-size: x-small;
    text-align: center;
    padding-left: 13px;
}

.busyRoadDescription {
    font-family: sans-serif;
    font-weight: bold;
    padding-left: 2.5px;
    font-style: italic;
}

.roadSelectionBox {
    position: absolute;
    z-index: 12;
    bottom: 10%;
    left: 5.5%;
    border-radius: 5%;
    border-style: solid;
    border-color: green;
    border-width: 4px;
    height: 100px;
    width: 68px;
    background-color: transparent;
}
