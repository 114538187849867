.navbarBackground {
    padding-top: 5px;
    background-color: rgb(21, 134, 84);
    min-width: max-content;
    height: 60px;
}

/* MOBILE STYLES */

#mobileNavbarBackground {
    background-color: rgb(21, 134, 84);
    padding: 10px;
}

/**/

.navbar-items {
    display: flex;
    width: 100%;
    min-width: 1180px;
}

.logo {
    height: 50px;
}

.navbar-item {
    color: white;
}

.navbar-fourcast-logo {    
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-fourcast-features {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    width: 90%;
    gap: 2px;
    justify-content: center;
    align-items: center;
}

.navbar-fourcast-services {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2px;
    justify-content: center;
    align-items: center;
}
