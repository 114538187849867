
.landingPageTop {
    background: url('../../Assets/manhattan_skyline.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    position: relative;
    overflow: hidden;
    height: 600px;
    background-color: black;
}

.welcomeArea {
    height: 600px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}


#welcomeFourcastLogo {
    height: 190px;
    width: 550px;
    z-index: 4;
}

/* MOBILE */

#landingPageMobileBackgroundPic {
    position: absolute;
    left: 25%;
    top: 50%;
    height: 125%;
    width: 300%;
    transform: translate(-50%, -50%);
}


#welcomeFourcastLogoMobile {
    height: 50%;
    width: 50%;
    z-index: 4;
}

/**/

#welcomeMotto {
    color: white;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    text-shadow: 3px 3px 6px black;
    z-index: 2;
}

#explorePropertiesBtn {
    background-color: rgb(21, 134, 84);
    color: white;
    width: 250px;
    outline: none;
    z-index: 3;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-decoration: none;
    font-size: 18px; 
    font-weight: large; 
    width: 275px; 
    height: 50px;
}

#explorePropertiesBtn:hover {
    transform: scale(1.02) perspective(0px);
}
