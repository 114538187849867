
.createReportSelections {
    padding-bottom: 100px;
    padding-top: 25px;
    display: grid;
    grid-template-columns: 4fr 5fr 5fr;
}

#reportWelcomePageGraphic {
    height: 300px;
    width: 250px;
    background-color: snow;
    margin-top: 25px;
}

#reportCoverPageFourcastLogoArea {
    z-index: 150;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
}

#reportCoverPageGreenSquare {
    background-color: rgb(21, 134, 84);
    height: 100px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
}

#reportCoverPageFourcastLogo {
    height: 75px;
    width: 225px;
}

#reportCoverPageAddress {
    color: black;
    z-index: 11;
    text-shadow: .5px .5px 1px rgb(21, 134, 84);
    letter-spacing: 1px;
    width: 100%;
    height: 150px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

#reportCoverPageListingPicArea {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 100%;
}

.reportCoverPageListingPicCard {
    max-height: 400px;
    height: 400px;
    width: 550px;
    max-width: 550px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#reportCoverPageListingPic {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

#reportAddressAndSize {
    height: 250px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#reportCoverPageSizeAndPrice {
    color: black;
    z-index: 11;
    text-shadow: .5px .5px 1px rgb(21, 134, 84);
    letter-spacing: 1px;
    min-width: 250px;
    max-width: 275px;
    height: 200px;
    overflow-x: hidden;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reportConfirmationArea {
    height: 800px;
    width: 100%;
}

#createReportBtn:hover {
    transform: scale(1.02) perspective(0px);
}

.downloadReportBtn:hover {
    transform: scale(1.02) perspective(0px);
}

#createReportBtn:hover {
    transform: scale(1.02) perspective(0px);
}

.reportAddress {
    color: black;
    z-index: 11;
    text-shadow: .5px .5px 1px rgb(21, 134, 84);
    letter-spacing: 1px;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-left: 20px;
    max-width: 90%;
    max-height: 40px;
    overflow-x: hidden;
}

.reportFourcastLogoArea {
    display: flex;
    justify-content: center;
    align-items: center;
}

.reportFourcastLogo {
    width: 75px;
    height: 25px;
}

.reportCatScore {
    color: white;
    font-weight: 500px;
    text-shadow: .5px .5px .5px black;
}

.staticMapReport {
    position: relative;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.staticMapReport::after {
    content: '';
    position: absolute;
    background-color: transparent;
    z-index: 10;
    width: 350px;
    height: 350px;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    border-radius: 50%;
    border: 3px solid red;
}

.trafficStaticMapReport {
    position: relative;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.trafficStaticMapReport::after {
    content: '';
    position: absolute;
    background-color: transparent;
    z-index: 10;
    width: 350px;
    height: 350px;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    border-radius: 50%;
    border: 3px solid red;
}

.reportStar {
    width: 25px;
    height: 25px;
}

.reportStar1 {
    width: 20px;
    height: 20px;
}

.reportStarDescription {
    font-family: sans-serif;
    font-size: small;
    font-style: italic;
    margin-left: 5px;
    margin-top: 5px;
}

.reportPoweredByGoogle {
    background: transparent;
}

.reportTopPlacesTitle {
    z-index: 13;
    color: black;
    font-size: 12;
    font-family: sans-serif;
    font-weight: bold;
    font-style: italic;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px white;
}

.poiDistanceReport {
    font-family: sans-serif;
    font-size: small;
    text-align: left;
    padding-left: 30px;
    height: 30px;
}

.popularTimesReport {
    z-index: 12;    
    background-color: white;
    width: 420px;
    height: 180px;
    position: relative;
}

#daytimeTrafficTitleReport {
    position: absolute;
    z-index: 12;
    color: black;
    font-family: sans-serif;
    text-align: center;
    font-weight: bold;
    width: 75px;
    height: 40px;
    font-size: 13px;
    bottom: 75%;
    left: 20%;
}

#nightlifeTitleReport {
    position: absolute;
    z-index: 12;
    color: black;
    width: 75px;
    height: 40px;
    font-family: sans-serif;
    font-size: 13px;
    text-align: center;
    font-weight: bold;
    bottom: 32%;
    left: 20%;
}

.sunReport {
    position: absolute;
    color: orange;
    z-index: 11;
    bottom: 78%;
    left: 85%;
    width: 32px;
    height: 32px;
}

.moonReport {
    position: absolute;
    color: midnightblue;
    z-index: 11;
    bottom: 40%;
    left: 85%;
    width: 32px;
    height: 32px;
}

.dayTrafficGaugeReport {
    --rotation: 90;
    position: absolute;
    bottom: 87%;
    left: 30%;
    width: 10px;
    height: 90%;
    background: linear-gradient(to top, rgb(228, 114, 103), rgb(250, 204, 68), rgb(52, 181, 122));
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}

.dayTrafficGaugePointerReport {
    position: absolute;
    --bottomDay: 100;
    left: 29%;
    bottom: 91%;
    transform-origin: bottom;
    z-index: 12;
    transform: translateX(calc(var(--bottomDay) * 1.5px));
    width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 12px solid black;
}

.dayTrafficGaugeHighReport {
    position: absolute;
    z-index: 12;
    bottom: 76%;
    left: 88%;
    font-size: 10px;
    font-weight: bold;
    color: #058f1c;
}

.dayTrafficGaugeLowReport {
    position: absolute;
    z-index: 11;
    bottom: 76%;
    left: 23%;
    font-size: 10px;
    font-weight: bold;
    color: #d80808;
}

.nightlifeGaugeReport {
    --rotation: 90;
    position: absolute;
    bottom: 50%;
    left: 30%;
    width: 10px;
    height: 90%;
    background: linear-gradient(to top, rgb(228, 114, 103), rgb(250, 204, 68), rgb(52, 181, 122));
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}

.nightlifeGaugePointerReport {
    position: absolute;
    --bottomNight: 100;
    left: 29%;
    bottom: 54%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomNight) * 1.5px));
    width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 12px solid black;
}

.nightlifeGaugeHighReport {
    position: absolute;
    z-index: 12;
    bottom: 39%;
    left: 88%;
    font-size: 10px;
    font-weight: bold;
    color: #058f1c;
}

.nightlifeGaugeLowReport {
    position: absolute;
    z-index: 12;
    bottom: 39%;
    left: 23%;
    font-size: 10px;
    font-weight: bold;
    color: #d80808;
}

.busyTimesModalReport {
    position: absolute;
    z-index: 12;
    bottom: 3%;
    left: 38%;
}

.greenArrowReport {
    width: 0;
	height: 0;
    border-left: 12px solid transparent;
	border-right: 12px solid transparent;
	border-bottom: 24px solid darkgreen;
}

.redArrowReport {
    width: 0;
	height: 0;
    border-left: 12px solid transparent;
	border-right: 12px solid transparent;
	border-top: 24px solid darkred;
}

.reportChildHouseCountGauge {
    --rotation: 0;
    position: absolute;
    bottom: 12.5%;
    left: 50%;
    width: 26px;
    height: 175px;
    background: linear-gradient(to top, rgb(228, 114, 103), rgb(187, 236, 205));
    transform-origin: bottom;
    border: 1px solid black;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}

.reportChildHouseCountPointer {
    position: absolute;
    --bottomChild: 100;
    right: 100%;
    transform-origin: bottom;
    z-index: 12;
    transform: translateY(calc(var(--bottomChild) * 1px));
    width: 0;
	height: 0;
	border-bottom: 6px solid transparent;
	border-top: 6px solid transparent;
	border-left: 12px solid black;
}

.reportChildHouseCountHigh {
    position: absolute;
    z-index: 12;
    top: 10%;
    left: 60%;
    font-size: small;
    font-weight: bold;
    color: black;
}

.reportChildHouseCountLow {
    position: absolute;
    z-index: 12;
    bottom: 6%;
    left: 61%;
    font-size: small;
    font-weight: bold;
    color: black;
}

.resPriceGaugeReport {
    position: absolute;
    left: 12.5%;
    bottom: 5%;
}

.resPriceNeedleReport {
    --rotation: 0;
    position: absolute;
    bottom: 11%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}

.resPriceGaugeReport::after {
    content: '';
    position: absolute;
    background-color: black;
    z-index: 11;
    width: 10px;
    height: 10px;
    bottom: 2%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.priceDataReport {
    position: absolute;
    left: 12.5%;
    bottom: 5%;
}

.priceGaugeNeedleReport {
    --rotation: 0;
    position: absolute;
    bottom: 11%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}

.priceDataReport::after {
    content: '';
    position: absolute;
    background-color: black;
    z-index: 11;
    width: 10px;
    height: 10px;
    bottom: 2%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

