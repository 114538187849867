.summaryFourcastScore {
    color: rgb(52, 181, 122);
    font-weight: 500px;
    text-shadow: .5px .5px .5px black;
}

.nearbyListingModalScore {
    font-size: 14px;
    width: 40px;
    height: 40px;
    font-family: sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black; 
    font-weight: bold;
    border: 4px solid darkgreen;
    border-radius: 50%;
}

.summaryScoreTitle {
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 500px;
}

.summaryCatScore {
    color: rgb(52, 181, 122);
    font-weight: 500px;
    text-shadow: .5px .5px .5px black;
}

.summaryDescription {
    font-size: 13px;
    font-weight: 500px;
    width: 85%;
}

.staticMapSummary {
    position: relative;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.staticMapSummary::after {
    content: '';
    position: absolute;
    background-color: transparent;
    z-index: 10;
    width: 250px;
    height: 250px;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    border-radius: 50%;
    border: 3px solid red;
}


/* SEARCHED POI CONTENT */


.searchedPoiName {
    font-weight: bold;
    font-size: 14px;
    margin-top: 15px;
    width: 90%;
    height: 40px;
    overflow: hidden;
    overflow-y: scroll;
}

.searchedPoiCircle {
    background-color: purple;
    z-index: 12;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
}

.searchedPoweredByGoogle {
    position: absolute;
    z-index: 12;
    left: 40%;
    bottom: 83%;
    background: transparent;
}