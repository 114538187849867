.competitionTagsArea {
    width: 100%;
    text-align: center;
    height: 50px;
    display: grid;
    grid-template-columns: 1fr 4fr;
    align-items: center;
}

.mobileCompetitionTagsArea {
    width: 100%;
    text-align: center;
    height: 100px;
    align-items: center;
    position: relative;
}

.headerScore {
    font-size: 28px; 
    font-family: sans-serif; 
    margin-left: 30px; 
    margin-right: 30px; 
    padding: 7px;
    color: black; 
    font-weight: bold;
    border: 4px solid darkgreen;
    border-radius: 50%;
}

.compTagsTag {
    margin-left: 15px;
    padding: 10px;
    background-color: rgba(14, 119, 45, 0.9);
    color: white;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 13px;
}

.compContent {
    height: 750px;
}

.priceGaugeHighlight {
    position: absolute;
    left: 40%;
    top: 17%;
}

.highEndGaugeTitle {
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    color: black;
    left: -70%;
    top: -10%;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px white;
}

.priceModalBtn {
    position: absolute;
    left: -60%;
    bottom: 15%;
}

.mobilePriceGaugeHighlight {
    position: absolute;
    left: 90%;
    top: 80%;
    transform: translate(-90%, -80%);
}

.mobileHighEndGaugeTitle {
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    color: black;
    left: -35%;
    top: -20%;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px white;
    width: 60%;
}

.mobilePriceModalBtn {
    position: absolute;
    left: -38%;
    bottom: 15%;
}

.ratingQualityTitle {
    font-size: 16px;
    font-weight: bold;
    position: absolute;
    text-align: center;
    right: 0%;
    top: -220%;
}

.yelpRatingHighlight {
    position: absolute;
    right: 20%;
    bottom: 20%;
}

.totalBizArea {
    position: absolute;
    left: 20%;
    top: 0%;
    height: 270px;
    width: 300px;
}

.mobileTotalBizArea {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 270px;
    width: 300px;
}

.commonBizArea {
    position: absolute;
    left: 5%;
    top: 7%;
    height: 300px;
    width: 450px;
}

.mobileCommonBizArea {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popularBizArea {
    position: absolute;
    left: 11%;
    top: 2%;
    width: 600px;
}

.popularBusinessModal {
    position: absolute;
    right: 5%;
    top: 1%;
    width: 190px;
}

.totalGaugeChart {
    max-width: 300px;
    max-height: 230px;
}

.totalGaugeNeedle {
    --rotation: 0;
    position: absolute;
    bottom: 40%;
    left: 50%;
    width: 8px;
    height: 30%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}

.totalBizArea::after {
    content: '';
    position: absolute;
    background-color: black;
    z-index: 10;
    width: 13px;
    height: 13px;
    bottom: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.mobileTotalBizArea::after {
    content: '';
    position: absolute;
    background-color: black;
    z-index: 10;
    width: 13px;
    height: 13px;
    bottom: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.totalBusinessNumber {
    position: absolute;
    bottom: 15%;
    left: 42.5%;
    text-align: center;
    z-index: 12;
}

.lowDensityLabel {
    position: absolute;
    bottom: 25%;
    left: -15%;
    text-align: center;
    z-index: 12;
    font-size: 10px;
    font-weight: bold;
}

.highDensityLabel {
    position: absolute;
    bottom: 25%;
    right: -15%;
    text-align: center;
    z-index: 12;
    font-size: 10px;
    font-weight: bold;
}

.mobileLowDensityLabel {
    position: absolute;
    bottom: 25%;
    left: 0%;
    text-align: center;
    z-index: 12;
    font-size: 10px;
    font-weight: bold;
}

.mobileHighDensityLabel {
    position: absolute;
    bottom: 25%;
    right: 0%;
    text-align: center;
    z-index: 12;
    font-size: 10px;
    font-weight: bold;
}

.totalBusinessText {
    margin-left: 78px;
    width: 50%;
    text-align: center;
    z-index: 12;
    font-size: 14px;
    font-weight: bold;
}

.modal-table {
    width: 880px;
}

.modal-chart {
    width: 175%;
}

.priceGauge {
    height: 100px;
    width: 235px;
    z-index: 9;
    background-color: transparent;
}

.priceGaugeNeedle {
    --rotation: 0;
    position: absolute;
    bottom: 15%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}

.priceData::after {
    content: '';
    position: absolute;
    background-color: black;
    z-index: 11;
    width: 10px;
    height: 10px;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.yelp-logo-header {
    height: 30px;
    width: 50px;
    padding-bottom: 8px;
}