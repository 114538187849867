.demographicsTagsArea {
    width: 100%;
    text-align: center;
    height: 50px;
    display: grid;
    grid-template-columns: 1fr 4fr;
    align-items: center;
}

.mobileDemographicsTagsArea {
    width: 100%;
    text-align: center;
    height: 100px;
    align-items: center;
    position: 'relative';
}

.headerScore {
    font-size: 28px; 
    font-family: sans-serif; 
    margin-left: 30px; 
    margin-right: 30px; 
    padding: 7px;
    color: black; 
    font-weight: bold;
    border: 4px solid darkgreen;
    border-radius: 50%;
}

.demoTagsTag {
    margin-left: 15px;
    padding: 10px;
    background-color: rgba(14, 119, 45, 0.9);
    color: white;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 13px;
}

.keyPointsData {
    width: 350px;
    height: 170px; 
}

.keyPointsContent {
    height: 170px;
}





#popIconHighlight {
    position: absolute;
    left: 10%;
    bottom: 75%;
    width: 48px;
    height: 36px;
}

#topPeopleTitle {
    position: absolute;
    top: 8%;
    left: 25%;
    font-size: 14px;
    font-weight: bold;
    font-family: sans-serif;
    text-align: left;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px white;
}


#highlightBox1 {
    position: absolute;
    top: 48%;
    left: 12.5%;
    height: 85px;
    width: 275px;
    text-align: center;
}

#residentsIcon {
    height: 30px;
    width: 50px;
}

#residentsDescription {
    margin-top: 22px;
    font-size: 11px;
    width: 50px;
    font-family: sans-serif;
    font-weight: bold;
}

#officeWorkersIcon {
    height: 36px;
    width: 36px;
}

#officeWorkersDescription {
    margin-top: 16px;
    font-size: 11px;
    width: 50px;
    font-family: sans-serif;
    font-weight: bold;
}

#touristIcon {
    height: 42px;
    width: 36px;
}

#touristsDescription {
    margin-top: 10px;
    font-size: 11px;
    width: 47px;
    font-family: sans-serif;
    font-weight: bold;
}

#studentsIcon {
    height: 42px;
    width: 42px;
    padding-bottom: 5px;
}

#studentsDescription {
    margin-top: 10px;
    font-size: 11px;
    width: 50px;
    font-family: sans-serif;
    font-weight: bold;
}




#resIconHighlight {
    position: absolute;
    left: 10%;
    bottom: 75%;
    width: 36px;
    height: 36px;
}

#resHighlightTitle {
    position: absolute;
    top: 8%;
    left: 25%;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    font-family: sans-serif;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px white;
}

.resPriceGauge {
    position: absolute;
    left: 23.5%;
    bottom: 5%;
}

.resPriceNeedle {
    --rotation: 0;
    position: absolute;
    bottom: 14%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}

.resPriceGauge::after {
    content: '';
    position: absolute;
    background-color: black;
    z-index: 11;
    width: 10px;
    height: 10px;
    bottom: 2%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}




#incomeIconHighlight {
    position: absolute;
    left: 10%;
    bottom: 75%;
    width: 42px;
    height: 42px;
}

#incomeHighlightTitle {
    position: absolute;
    left: 25%;
    top: 8%;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    font-family: sans-serif;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px white;
}

.individualIncomeHighlight {
    position: absolute;
    top: 45%;
    left: 15%;
}

#averageIncomeTitle {
    position: absolute;
    left: 15%;
    top: 35%;
    font-size: 11px;
    font-weight: bold;
    font-family: sans-serif;
}

#individualIncomeHighlightValue {
    font-size: 16px;
    font-weight: bold;
    font-family: sans-serif;
    color: darkgreen;
    z-index: 12;
}

#householdIncomeTitle {
    position: absolute;
    right: 15%;
    top: 35%;
    font-size: 11px;
    font-weight: bold;
    font-family: sans-serif;
}

.householdIncomeHighlight {
    position: absolute;
    top: 45%;
    right: 15%;
}

#householdIncomeHighlightValue {
    font-size: 16px;
    font-weight: bold;
    font-family: sans-serif;
    color: darkgreen;
    z-index: 12;
}

#indAvg {
    font-size: 10px;
    font-weight: bold;
    font-family: sans-serif;
    color: darkgreen;
    z-index: 12;
    margin-left: 5px;
}

#houseAvg {
    font-size: 10px;
    font-weight: bold;
    font-family: sans-serif;
    color: darkgreen;
    z-index: 12;
    margin-left: 5px;
}

.incomeGauge {
    --rotation: 90;
    position: absolute;
    bottom: 20%;
    left: 30%;
    width: 10px;
    height: 100%;
    background: linear-gradient(to top, rgb(228, 114, 103), rgb(250, 204, 68), rgb(52, 181, 122));
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}

.incomeGaugePointer {
    margin-left: 7px;
    position: absolute;
    --bottomIncome: 100;
    left: 29%;
    bottom: 24%;
    transform-origin: left;
    z-index: 12;
    transform: translateX(calc(var(--bottomIncome) * 1.5px));
    border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 12px solid black;
}

.incomeGaugeHigh {
    position: absolute;
    z-index: 12;
    bottom: 7%;
    left: 75%;
    font-size: 10px;
    font-weight: bold;
    color: #058f1c;
}

.incomeGaugeLow {
    position: absolute;
    z-index: 12;
    bottom: 7%;
    left: 25%;
    font-size: 10px;
    font-weight: bold;
    color: #d80808;
}




.demoContent {
    height: 900px;
    width: 1200px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    padding: 20px 5px;
}

.trendingArea {
    position: absolute;
    right: 15%;
    top: 3%;
}

.trendingTitle {
    font-size: 16px;
    font-weight: bold;
    font-family: sans-serif;
}

.redArrow {
    height: 20px;
    width: 15px;
}

.greenArrow {
    height: 20px;
    width: 15px;
}

.trendingHeaderTitle {
    position: absolute;
    z-index: 13;
    top: 100%;
    right: 40%;
    font-weight: bold;
    font-family: sans-serif;
    font-size: 12px;
    text-align: center;
}





.trendCategoryTitle {
    position: absolute;
    bottom: 70%;
    left: 30%;
    font-size: 11px;
    font-family: sans-serif;
    font-weight: bold;
    text-align: right;
    width: 20%;
}

.incomeChange2010 {
    position: absolute;
    bottom: 70%;
    left: 60%;
    font-size: 12px;
    font-family: sans-serif;
    font-weight: bold;
    text-align: center;
    width: 15%;
}

.fiveForecast {
    position: absolute;
    bottom: 70%;
    left: 80%;
    font-size: 12px;
    font-family: sans-serif;
    font-weight: bold;
    text-align: center;
    width: 15%;
}

.incomeArrows {
    position: absolute;
    bottom: 7%;
    left: 67%;
}

.incomeDataSection {
    position: relative;
    z-index: 13;
    width: 525px;
    height: 340px;
}

.incomeTitle {
    position: absolute;
    z-index: 12;
    color: black;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: bold;
    bottom: 73%;
    left: 8.5%;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px white;
}

.incomeIcon {
    position: absolute;
    left: 9.5%;
    top: 5%;
    height: 42px;
    width: 42px;
}

.incomeGraph {
    max-width: 325px;
    position: absolute;
    top: 20%;
    left: 20%;
}

.incomeChangeValue {
    margin-left: -20px;
    padding-top: 10px;
    font-weight: bold;
    font-family: sans-serif;
    font-size: 18px;
}

.incomeStatTitle {
    font-size: small;
    font-family: sans-serif;
    font-weight: bold;
    text-align: center;
    width: 75%;
}








.resDataSection {
    position: relative;
    z-index: 13;
    width: 525px;
    height: 340px;
    margin-top: 40px;
}

.resTitle {
    position: absolute;
    z-index: 12;
    color: black;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    width: 100px;
    top: 19%;
    left: 3%;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px white;
}

.resIcon {
    position: absolute;
    left: 9%;
    top: 6%;
    height: 36px;
    width: 36px;
}

.medSalePrice {
    position: absolute;
    left: 35%;
    top: 10%;
    text-align: center;
}

.resPricesMedianSales {
    font-weight: bold;
    font-family: sans-serif;
    text-align: center;
    font-size: 12px;
    margin-bottom: 10px;
}

#medianSalePrice {
    font-size: 20px;
    font-weight: bold;
    font-family: sans-serif;
    color: darkslategray;
    z-index: 12;
}

.rentOwn {
    max-width: 300px;
    position: absolute;
    left: 25%;
    top: 25%;
}






.populationDataSection {
    position: relative;
    z-index: 13;
    height: 800px;
    width: 575px;
}

.populationTitle {
    position: absolute;
    z-index: 12;
    color: black;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 16px;
    bottom: 88%;
    left: 5%;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px white;
}

.populationIcon {
    position: absolute;
    left: 6.5%;
    top: 2%;
    height: 36px;
    width: 52px;
}

.trendCategoryTitle {
    font-size: 12px;
    font-family: sans-serif;
    font-weight: bold;
    text-align: right;
    width: 20%;
}

#popTrendTitle {
    position: absolute;
    bottom: 88%;
    left: 40%;
    width: 10%;
}

#popChange2010 {
    position: absolute;
    bottom: 88%;
    left: 60%;
    font-size: 12px;
    font-family: sans-serif;
    font-weight: bold;
    text-align: center;
    width: 15%;
}

#popFiveForecast {
    position: absolute;
    bottom: 88%;
    left: 80%;
    font-size: 12px;
    font-family: sans-serif;
    font-weight: bold;
    text-align: center;
    width: 15%;
}

#popTenForecast {
    position: absolute;
    bottom: 86%;
    left: 80%;
    font-size: 12px;
    font-family: sans-serif;
    font-weight: bold;
    text-align: center;
    width: 15%;
}

.popChangeValue {
    margin-left: -20px;
    padding-top: 10px;
    font-weight: bold;
    font-family: sans-serif;
    font-size: 18px;
}

.popStatTitle {
    font-size: small;
    font-family: sans-serif;
    font-weight: bold;
    text-align: center;
    width: 75%;
}

.popArrows {
    position: absolute;
    bottom: 7%;
    left: 67%;
}

.ageGraph {
    max-width: 350px;
}

.ageSection {
    padding-top: 25px;
}

.fiveYearTitle {
    position: absolute;
    z-index: 13;
    bottom: 60%;
    right: 13%;
    font-family: sans-serif;
    font-weight: bold;
    font-size: small;
}

.fiveYearNumber {
    position: absolute;
    z-index: 13;
    bottom: 59%;
    right: 6%;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 24px;
}

.tenYearTitle {
    position: absolute;
    z-index: 13;
    bottom: 51%;
    right: 13%;
    font-family: sans-serif;
    font-weight: bold;
    font-size: small;
}

.tenYearNumber {
    position: absolute;
    z-index: 13;
    bottom: 50%;
    right: 6%;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 24px;
}

.projectedDesc {
    position: absolute;
    z-index: 13;
    bottom: 70%;
    left: 65%;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    width: 30%;
}

.popHeaderTitle {
    position: absolute;
    z-index: 13;
    bottom: 53%;
    left: 6%;
    font-weight: bold;
    font-family: sans-serif;
    font-size: 14px;
}

.familyGraph {
    max-width: 285px;
    position: absolute;
    bottom: 5%;
    left: 0%;
}

.childrenHouseTitle {
    position: absolute;
    z-index: 13;
    bottom: 30%;
    left: 65%;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    width: 30%;
}

.mobileChildrenHouseTitle {
    position: absolute;
    z-index: 13;
    top: 40%;
    left: 25%;
    transform: translate(-25%, -40%);
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    width: 40%;
}

.childHouseCountGauge {
    --rotation: 0;
    position: absolute;
    bottom: 10%;
    left: 90%;
    width: 26px;
    height: 15%;
    background: linear-gradient(to top, rgb(228, 114, 103), rgb(187, 236, 205));
    transform-origin: bottom;
    border: 1px solid black;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}

.mobileChildHouseCountGauge {
    --rotation: 0;
    position: absolute;
    top: 50%;
    left: 80%;
    width: 26px;
    height: 40%;
    background: linear-gradient(to top, rgb(228, 114, 103), rgb(187, 236, 205));
    transform-origin: bottom;
    border: 1px solid black;
    z-index: 10;
    transform: translate(-80%, -50%) rotate(calc(var(--rotation) * 1deg));
}

.childHouseCountPointer {
    position: absolute;
    --bottom: 90;
    right: 105%;
    transform-origin: bottom;
    z-index: 12;
    transform: translateY(calc(var(--bottom) * 1px));
    width: 0;
	height: 0;
	border-bottom: 6px solid transparent;
	border-top: 6px solid transparent;
	border-left: 12px solid black;
}

.childHouseCountHigh {
    position: absolute;
    z-index: 12;
    bottom: 26%;
    left: 87.25%;
    font-size: small;
    font-weight: bold;
    color: black;
}

.childHouseCountLow {
    position: absolute;
    z-index: 12;
    bottom: 6%;
    left: 87.75%;
    font-size: small;
    font-weight: bold;
    color: black;
}

.mobileChildHouseCountHigh {
    position: absolute;
    z-index: 12;
    top: 20%;
    left: 77.25%;
    font-size: small;
    font-weight: bold;
    color: black;
}

.mobileChildHouseCountLow {
    position: absolute;
    z-index: 12;
    top: 75%;
    left: 77.75%;
    font-size: small;
    font-weight: bold;
    color: black;
}

.famChildren {
    position: absolute;
    bottom: 18%;
    left: 68%;
    height: 38px;
    width: 38px;
}

.child {
    position: absolute;
    bottom: 18%;
    left: 64%;
    height: 24px;
    width: 24px;
}

.stroller {
    position: absolute;
    bottom: 18%;
    left: 74%;
    height: 24px;
    width: 24px;
}

.childHouseCountNumber {
    position: absolute;
    bottom: 10%;
    left: 65.25%;
    font-weight: bold;
    font-family: sans-serif;
    font-size: 32px;
}

.mobileChildHouseCountNumber {
    position: absolute;
    top: 65%;
    left: 30%;
    transform: translate(-30%, -65%);
    font-weight: bold;
    font-family: sans-serif;
    font-size: 32px;
}