/* skeleton styles */

.skeleton {
    background-color: lightgray;
    margin: 10px 0;
    border-radius: 4px;
}

.skeleton-filterBox {
    background: #f2f2f2;
    border-radius: 4px;
    padding: 20px;
}

.skeleton-map {
    background: #f2f2f2;
    border-radius: 4px;
    padding: 15px;
}

.skeleton-banner {
    background: #f2f2f2;
    border-radius: 4px;
    width: 100%;
    height: 125px;
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr  1fr;
    gap: 20px;
}

.skeleton.text {
    width: 100%;
    height: 20px;
}

.skeleton.title {
    width: 50%;
    height: 30px;
}

.skeleton.pill {
    width: 50%;
    height: 40px;
}

.skeleton.map {
    width: 100%;
    height: 575px;
    background-color: lightgray;
    border-radius: 4px;
}

.skeleton-wrapper {
    display: grid;
    grid-template-columns: 4fr 6fr;
    gap: 60px;
    background-color: white;
    padding: 10px 15px;
    position: relative;
    overflow: hidden;
}

.skeleton-wrapper-map {
    display: grid;
    grid-template-columns: 6fr 4fr;
    gap: 60px;
    background-color: white;
    padding: 10px 15px;
    position: relative;
    overflow: hidden;
}

/* filter box styling */

.catPills {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 50px;
}

.filterPills {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    align-items: center;
    margin-top: 25px;
}

/* map styling */

.skeleton-map-marker {
    position: absolute;
    left: 29%;
    top: 46%;
    height: 50px;
    width: 50px;
    color: darkgray;
}

/* details page styling */

.skeleton-details-category {
    margin-top: 30px;
    width: 85%;
    height: 135px;
    background: #f2f2f2;
    padding: 10px 10px;
    border-radius: 4px;
}

.skeleton-map-marker-details {
    position: absolute;
    left: 29%;
    top: 58%;
    height: 50px;
    width: 50px;
    color: darkgray;
}

/* animation effect */

.shimmer-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: loading 2.5s infinite;
}

.shimmer {
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    transform: skewX(-20deg);
    box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05);
}

@keyframes loading {
    0% { transform: translateX(-150%) }
    50% { transform: translateX(-60%) }
    100% { transform: translateX(150%) }
}
