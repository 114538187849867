
.infoWindow {
    width: 275px;
}

/*
    Marker colors {
        color: #b00238;
        color: #e82f2a;
        color: #f0b240;
        color: #6aaa30;
        color: #266621;
    }
*/

.infoWindowScore {
    font-size: 16px;
    padding-bottom: 10px;
    margin-left: 5px;
}

#bottomInfoScore {
    margin-bottom: 5px;
    margin-left: 5px;
}

.showAllBtn {
    position: absolute;
    top: 12%;
    left: 3%;
    font-size: 18px;
    font-weight: bold;
    border: 2px solid black;
    color: green;
    height: 50px;
    width: 200px;
    z-index: 14;
}

.customSearchInfoBox {
    width: 100%;
    position: relative;
    height: 85%;
}

.customSearchInfoBoxDesc {
    font-size: 10;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    width: 100%;
}

.customSearchInfoBoxScore {
    height: 40px;
    width: 40px;
}

.filterBox {
    z-index: 12;
    border-left: 1px solid lightgray;
    background-color: snow;
    overflow: hidden;
    overflow-y: scroll;
    position: relative;
    height: 90vh;
}

#filterHeader {
    margin-left: 24px;
    margin-bottom: 15px;
    margin-top: 20px;
    font-weight: 500px;
}

.filterBtnFab {
    margin-left: auto;
    margin-right: auto;
}

#priceAndSizeFilters {
    display: inline-flex;
    overflow: hidden;
    overflow-x: scroll;
    height: 100px;
    margin-left: 5px;
}

.priceAndSizeAndCategoryTitle {
    font-size: 12px;
    font-family: sans-serif;
    font-weight: bolder;
    margin-bottom: 5px;
}

#squareFootage {
    position: relative;
    margin-left: 20px;
    height: 80px;
    width: 200px;
}

#squareFootageInput {
    display: inline-flex;
}

#pricePerSquareFoot {
    position: relative;
    margin-left: 20px;
    height: 145px;
    width: 225px;
}

#pricePerSquareFootInput {
    display: inline-flex;
}

#includeUndisclosed {
    position: relative;
    width: 350px;
    height: 75px;
    display: flex;
    justify-content: left;
    align-items: center;
}

.whatIsThisButton:hover {
    transform: scale(1.02) perspective(0px);
}

.categoryScoresArea {
    position: relative;
    height: 100px;
    margin-top: 15px;
    margin-left: 24px;
}

.categoryScoresDescription {
    font-size: 11px;
    font-family: sans-serif;
    margin-bottom: 5px;
}

#eighty {
    font-size: 14px;
    font-family: sans-serif;
    font-weight: bold;
    margin-bottom: 5px;
}

.categoryButtons {
    width: 250px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.demoFilters {
    position: relative;
    height: 325px;
    text-align: center;
    overflow: hidden;
    overflow-x: scroll;
}

.trafficFilters {
    position: relative;
    height: 300px;
    text-align: center;
    overflow: hidden;
    overflow-x: scroll;
}

.competitionFilters {
    position: relative;
    height: 275px;
    overflow: hidden;
    overflow-x: scroll;
}

.propertyFilters {
    position: relative;
    height: 275px;
    overflow: hidden;
    overflow-x: scroll;
}

.filterType {
    margin-top: 10px;
    margin-bottom: 20px;
    padding-left: 24px;
    text-align: left;
    width: 100%;
}

.filterHeaderIcon {
    width: 32px;
    height: 32px;
}

.columnHeader {
    font-size: 12px;
    width: 100%;
    font-weight: bold;
    text-align: center;
    padding-bottom: 15px;
    text-decoration: underline;
}

.peopleFilter {
    text-align: center;
    font-weight: bold;
    margin-left: 20px;
}

#resTitle {
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    text-decoration: underline;
}

.trafficDrivers {
    text-align: center;
    font-weight: bold;
    margin-left: 20px;
}

.busyTimeFilters {
    text-align: center;
    font-weight: bold;
    margin-left: 20px;
}

.bizDensityFilter {
    text-align: center;
    font-weight: bold;
    margin-left: 20px;
}

.propertyFilter {
    text-align: center;
    font-weight: bold;
    margin-left: 20px;
}

.areaFilter {
    text-align: center;
    font-weight: bold;
    margin-left: 20px;
}

.ageFilter {
    text-align: center;
    font-weight: bold;
    margin-left: 20px;
}

.productPriceFilter {
    text-align: center;
    font-weight: bold;
    margin-left: 20px;
}

.filterTitle {
    font-size: 10px;
    padding-bottom: 5px;
    align-items: center;
    line-height: 13px;
}


.propFilterSection {
    margin-top: 40px;
    width: 450px;
    height: 100px;
    text-align: center;
}

.propFilterBtn {
    margin-top: 15px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 12px;
}

.selectedAddressInfoBox {
    color: black;
    background-color: white;
    height: 325px;
    width: 375px;
}

.fourcastMatchCalculatingExample1 {
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    animation: linearwipe 1s ease-in-out 1 forwards;
    animation-delay: 1500ms;
}

.fourcastMatchCalculatingExample2 {
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    animation: linearwipe 1s ease-in-out 1 forwards;
    animation-delay: 5000ms;
}

@keyframes linearwipe{ 
    0% { width: 100%; }
    100% { width: 0%; }
} 

/* MOBILE MAP STUFF */

.mobileModeMapTopFiltersContainer {
    position: absolute;
    top: 0%;
    width: 100%;
    height: 10%;
    background-color: whitesmoke;
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
}
  