.propertyTagsArea {
    width: 100%;
    text-align: center;
    height: 50px;
    display: grid;
    grid-template-columns: 1fr 4fr;
    align-items: center;
}

.mobilePropertyTagsArea {
    width: 100%;
    text-align: center;
    height: 100px;
    align-items: center;
    overflow-x: scroll;
}

.propTagsTag {
    margin-left: 15px;
    padding: 10px;
    background-color: rgba(14, 119, 45, 0.9);
    color: white;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 13px;
}


.propertyHighlights {
    margin-top: 30px;
}

.attributesTitle {
    z-index: 13;
    color: black;
    font-size: 18px;
    font-family: sans-serif;
    font-weight: bold;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px white;
}

.attributeName {
    font-size: 12px;
    font-weight: bold;
}

.attributeValue {
    font-size: 24px;
}

.propertyHighlightDescription {
    margin-top: 10px;
    font-size: 11px;
    font-family: sans-serif;
    font-weight: bold;
    width: 50px;
    text-align: center;
    line-height: 15px;
}

.propTagsFeature {
    height: 35px;
    margin-left: 15px;
    padding: 7px;
    background-color: rgba(14, 119, 45, 0.9);
    color: white;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 12px;
}


.frontageData {
    position: relative;
    z-index: 12;
    width: 310px;
    height: 130px;
}

.frontageIcon {
    position: absolute;
    z-index: 13;
    bottom: 30%;
    left: 25%;
    height: 64px;
    width: 64px;
}

.frontageMeasure {
    --rotation: 90;
    position: absolute;
    bottom: 25%;
    left: 27%;
    width: 12px;
    height: 40%;
    border-bottom: 2px solid maroon;
    border-top: 2px solid maroon;
    border-right: 2px solid maroon;
    transform-origin: bottom;
    z-index: 11;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}

.frontageValue {
    width: 125px;
    text-align: center;
    position: absolute;
    bottom: 30%;
    right: 0%;
    z-index: 13;
    font-size: 48px;
    height: 60px;
}

.visibility {
    position: relative;
    z-index: 12;
    width: 310px;
    height: 150px;
    padding-top: 15px;
    padding-left: 25px;
}

.roadDescription {
    margin-top: 10px;
    padding-top: 35px;
    padding-left: 8px;
    font-family: sans-serif;
    font-size: x-small;
    text-align: center;
    width: 70px;
}

.roadDescription2 {
    margin-top: 10px;
    padding-top: 35px;
    font-family: sans-serif;
    font-size: x-small;
    text-align: center;
    padding-left: 13px;
}

.busyRoadDescription {
    font-family: sans-serif;
    font-weight: bold;
    padding-left: 2.5px;
    font-style: italic;
}

