

.sideGrades {
    width: 99%;
    margin-left: 5px;
    padding-bottom: 15px;
}

.sideGrade {
    width: 25%;
    height: 70px;
}

.gradeType {
    font-size: 16px;
}

.gradeScore {
    font-size: 32px;
    text-shadow: .5px .5px .5px black;
}


.bannerTextSummary {
    color: black;
    z-index: 11;
    text-shadow: .5px .5px 1px rgb(21, 134, 84);
    letter-spacing: 1px;
}

.mainContent {
    position: relative;
    margin-top: 20px;
}