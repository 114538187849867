.editListingPropFeatureSection {
    background-color: white;
    width: 300px;
    height: 130px;
    border-radius: 5px;
    border: 1px solid lightgray;
}

.propFeatureBtn {
    margin-top: 5px;
    margin-left: 10px;
    font-size: 11px;
}

#editListingSaveArea {
    text-align: center;
    width: 35%;
    margin-left: auto;
    margin-right: auto;
}