.addListing {
    width: 900px;
    margin: auto;
    margin-top: 10px;
    padding: 40px;
}

.selectPropFeatureSection {
    background-color: white;
    width: 450px;
    height: 100px;
    border-radius: 5px;
    border: 1px solid lightgray;
}

.selectAllThatApply {
    font-size: 11px;
}