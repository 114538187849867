.customInfoWindow {
    max-width: 225px;
}

.customInfoWindowScore {
    font-size: 16px;
    padding-bottom: 15px;
}

#customBottomInfoScore {
    margin-bottom: 10px;
}

.customCardBackgroundColor {
    background-color: white;
    background-image:
        linear-gradient(
        to bottom, 
            rgba(21, 134, 84, .1),
            white
        );
}

#customSearchWelcomePage {
    width: 70%; 
    height: 650px;
    background-color: white;
    background-image:
        linear-gradient(
        to bottom, 
            rgba(21, 134, 84, .1),
            white
        );
    transition: height 1s linear 0.5s, width 1s ease-in 0.5s, background-color .5s linear;
}

#customSearchWelcomePageLoading {
    width: 100%;
    height: 100%;
    background-color: rgba(21, 134, 84, .8);
    transition: all 1s linear;
}

#fourcastMatchLogoWelcomePage {
    margin-top: 15px;
    transition: margin-top 1s ease-in-out .5s;
}

#fourcastMatchLogoWelcomePageLoading {
    margin-top: 250px;
    border-radius: 5px;
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.fourcastMatchWelcomePageDesc {
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.fourcastMatchWelcomePageGif {
    animation: fadeInAnimation ease 1.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.fourcastMatchWelcomePageButtons {
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

  
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}

#bizListModalBtn:hover {
    transform: scale(1.2) perspective(0px);
}

#matchCompareBtnShake {
    animation: shake 0.5s;
    animation-iteration-count: 1;
}
