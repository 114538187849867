
.matchComparisonCategoryCol {
    width: 15%;
}

.matchComparisonCompareListingsMapButton:hover {
    transform: scale(1.02) perspective(0px);
}


/* DAY TRAFFIC & NIGHTLIFE GAUGE AREA !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/ 
.matchComparisonDayTrafficComparisonGauge {
    --rotation: 90;
    position: absolute;
    bottom: 25%;
    left: 4%;
    width: 15px;
    height: 700px;
    background: linear-gradient(to top, rgb(228, 114, 103), rgb(250, 204, 68), rgb(52, 181, 122));
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-4%) rotate(calc(var(--rotation) * 1deg));
}

.matchComparisonDayTrafficComparisonGaugeHigh {
    position: absolute;
    z-index: 12;
    bottom: 0%;
    left: 92%;
    font-size: 10px;
    font-weight: bold;
    color: #058f1c;
}

.matchComparisonDayTrafficComparisonGaugeLow {
    position: absolute;
    z-index: 12;
    bottom: 0%;
    left: 4%;
    font-size: 10px;
    font-weight: bold;
    color: #d80808;
}

.matchComparisonNightlifeComparisonGauge {
    --rotation: 90;
    position: absolute;
    bottom: 25%;
    left: 4%;
    width: 15px;
    height: 700px;
    background: linear-gradient(to top, rgb(228, 114, 103), rgb(250, 204, 68), rgb(52, 181, 122));
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-4%) rotate(calc(var(--rotation) * 1deg));
}

.matchComparisonNightlifeComparisonGaugeHigh {
    position: absolute;
    z-index: 12;
    bottom: 0%;
    left: 92%;
    font-size: 10px;
    font-weight: bold;
    color: #058f1c;
}

.matchComparisonNightlifeComparisonGaugeLow {
    position: absolute;
    z-index: 12;
    bottom: 0%;
    left: 4%;
    font-size: 10px;
    font-weight: bold;
    color: #d80808;
}



/* DAYTIME TRAFFIC POINTERS */
/* The gauge width is 350px and the listing's daytime traffic score is out of 100 so therefore the transformation is the score * 3.5px  */
.matchComparisonDaytimeComparisonPointer1 {
    position: absolute;
    --bottomDay: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: bottom;
    z-index: 12;
    transform: translateX(calc(var(--bottomDay) * 7.2px));
    width: 0;
	height: 0;
	border-left: 9.5px solid transparent;
	border-right: 9.5px solid transparent;
	border-top: 19px solid rgb(254, 39, 18);
}
.matchComparisonDaytimeComparisonPointer2 {
    position: absolute;
    --bottomDay: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: bottom;
    z-index: 12;
    transform: translateX(calc(var(--bottomDay) * 7.15px));
    width: 0;
	height: 0;
	border-left: 9.25px solid transparent;
	border-right: 9.25px solid transparent;
	border-top: 18.5px solid rgb(102, 176, 50);
}
.matchComparisonDaytimeComparisonPointer3 {
    position: absolute;
    --bottomDay: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: bottom;
    z-index: 12;
    transform: translateX(calc(var(--bottomDay) * 7.1px));
    width: 0;
	height: 0;
	border-left: 9px solid transparent;
	border-right: 9px solid transparent;
	border-top: 18px solid rgb(251, 153, 2);
}
.matchComparisonDaytimeComparisonPointer4 {
    position: absolute;
    --bottomDay: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: bottom;
    z-index: 12;
    transform: translateX(calc(var(--bottomDay) * 7px));
    width: 0;
	height: 0;
	border-left: 8.75px solid transparent;
	border-right: 8.75px solid transparent;
	border-top: 17.5px solid rgb(52, 124, 152);
}
.matchComparisonDaytimeComparisonPointer5 {
    position: absolute;
    --bottomDay: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: bottom;
    z-index: 12;
    transform: translateX(calc(var(--bottomDay) * 6.95px));
    width: 0;
	height: 0;
	border-left: 9.5px solid transparent;
	border-right: 9.5px solid transparent;
	border-top: 19px solid rgb(254, 240, 51);
}
.matchComparisonDaytimeComparisonPointer6 {
    position: absolute;
    --bottomDay: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: bottom;
    z-index: 12;
    transform: translateX(calc(var(--bottomDay) * 6.9px));
    width: 0;
	height: 0;
	border-left: 9.25px solid transparent;
	border-right: 9.25px solid transparent;
	border-top: 18.5px solid rgb(128, 0, 0);
}
.matchComparisonDaytimeComparisonPointer7 {
    position: absolute;
    --bottomDay: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: bottom;
    z-index: 12;
    transform: translateX(calc(var(--bottomDay) * 6.85px));
    width: 0;
	height: 0;
	border-left: 9px solid transparent;
	border-right: 9px solid transparent;
	border-top: 18px solid rgb(2, 71, 254);
}
.matchComparisonDaytimeComparisonPointer8 {
    position: absolute;
    --bottomDay: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: bottom;
    z-index: 12;
    transform: translateX(calc(var(--bottomDay) * 6.8px));
    width: 0;
	height: 0;
	border-left: 8.75px solid transparent;
	border-right: 8.75px solid transparent;
	border-top: 17.5px solid rgb(134, 1, 175);
}

/* NIGHTLIFE POINTERS */
/* The gauge width is 350px and the listing's nightlife score is out of 100 so therefore the transformation is the score * 3.5px  */
.matchComparisonNightlifeComparisonPointer1 {
    position: absolute;
    --bottomNight: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomNight) * 7.2px));
    width: 0;
	height: 0;
	border-left: 9.5px solid transparent;
	border-right: 9.5px solid transparent;
	border-top: 19px solid rgb(254, 39, 18);
}
.matchComparisonNightlifeComparisonPointer2 {
    position: absolute;
    --bottomNight: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomNight) * 7.15px));
    width: 0;
	height: 0;
	border-left: 9.25px solid transparent;
	border-right: 9.25px solid transparent;
	border-top: 18.5px solid rgb(102, 176, 50);
}
.matchComparisonNightlifeComparisonPointer3 {
    position: absolute;
    --bottomNight: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomNight) * 7.1px));
    width: 0;
	height: 0;
	border-left: 9px solid transparent;
	border-right: 9px solid transparent;
	border-top: 18px solid rgb(251, 153, 2);
}
.matchComparisonNightlifeComparisonPointer4 {
    position: absolute;
    --bottomNight: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomNight) * 7.05px));
    width: 0;
	height: 0;
	border-left: 8.75px solid transparent;
	border-right: 8.75px solid transparent;
	border-top: 17.5px solid rgb(52, 124, 152);
}
.matchComparisonNightlifeComparisonPointer5 {
    position: absolute;
    --bottomNight: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomNight) * 7px));
    width: 0;
	height: 0;
	border-left: 9.5px solid transparent;
	border-right: 9.5px solid transparent;
	border-top: 19px solid rgb(224, 254, 51);
}
.matchComparisonNightlifeComparisonPointer6 {
    position: absolute;
    --bottomNight: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomNight) * 6.95px));
    width: 0;
	height: 0;
	border-left: 9.25px solid transparent;
	border-right: 9.25px solid transparent;
	border-top: 18.5px solid rgb(128, 0, 0);
}
.matchComparisonNightlifeComparisonPointer7 {
    position: absolute;
    --bottomNight: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomNight) * 6.9px));
    width: 0;
	height: 0;
	border-left: 9px solid transparent;
	border-right: 9px solid transparent;
	border-top: 18px solid rgb(2, 71, 254);
}
.matchComparisonNightlifeComparisonPointer8 {
    position: absolute;
    --bottomNight: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomNight) * 6.85px));
    width: 0;
	height: 0;
	border-left: 8.75px solid transparent;
	border-right: 8.75px solid transparent;
	border-top: 17.5px solid rgb(134, 1, 175);
}

/* REVIEW COUNT COMPARISON AREA WITH GAUGE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
.matchComparisonReviewCountComparisonPointer1 {
    position: absolute;
    --bottomReview: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomReview) * 3.5px));
    width: 0;
	height: 0;
	border-left: 9.5px solid transparent;
	border-right: 9.5px solid transparent;
	border-top: 19px solid rgb(52, 181, 122);
}
.matchComparisonReviewCountComparisonBackup1 {
    position: absolute;
    --bottomReview: 1;
    left: 2%;
    bottom: 55%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomReview) * 3.5px));
    width: 5px;
	height: 5px;
	border-radius: 50%;
	background-color: rgb(52, 181, 122);
}
.matchComparisonReviewCountComparisonPointer2 {
    position: absolute;
    --bottomReview: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomReview) * 3.5px));
    width: 0;
	height: 0;
	border-left: 9.5px solid transparent;
	border-right: 9.5px solid transparent;
	border-top: 19px solid rgb(223, 82, 69);
}
.matchComparisonReviewCountComparisonBackup2 {
    position: absolute;
    --bottomReview: 1;
    left: 2.5%;
    bottom: 55%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomReview) * 3.5px));
    width: 13.5px;
	height: 13.5px;
	border-radius: 50%;
	background-color: rgb(223, 82, 69);
}
.matchComparisonReviewCountComparisonPointer3 {
    position: absolute;
    --bottomReview: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomReview) * 3.5px));
    width: 0;
	height: 0;
	border-left: 9.5px solid transparent;
	border-right: 9.5px solid transparent;
	border-top: 19px solid rgb(250, 204, 68);
}
.matchComparisonReviewCountComparisonBackup3 {
    position: absolute;
    --bottomReview: 1;
    left: 2%;
    bottom: 55%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomReview) * 3.5px));
    width: 5px;
	height: 5px;
	border-radius: 50%;
	background-color: rgb(250, 204, 68);
}
.matchComparisonReviewCountComparisonPointer4 {
    position: absolute;
    --bottomReview: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomReview) * 3.5px));
    width: 0;
	height: 0;
	border-left: 9.5px solid transparent;
	border-right: 9.5px solid transparent;
	border-top: 19px solid rgb(30, 144, 255);
}

/* RES PRICES COMPARISON GAUGES !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
.matchComparisonResPriceGaugeComparison {
    position: relative;
    padding-left: 20px;
    padding-top: 10px;
}

.matchComparisonResPriceGaugeComparison::after {
    content: '';
    position: absolute;
    background-color: black;
    z-index: 11;
    width: 10px;
    height: 10px;
    bottom: 2%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.matchComparisonResPriceComparisonNeedle1 {
    --rotation: 0;
    position: absolute;
    bottom: 14%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.matchComparisonResPriceComparisonNeedle2 {
    --rotation: 0;
    position: absolute;
    bottom: 14%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.matchComparisonResPriceComparisonNeedle3 {
    --rotation: 0;
    position: absolute;
    bottom: 14%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.matchComparisonResPriceComparisonNeedle4 {
    --rotation: 0;
    position: absolute;
    bottom: 14%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.matchComparisonResPriceComparisonNeedle5 {
    --rotation: 0;
    position: absolute;
    bottom: 14%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.matchComparisonResPriceComparisonNeedle6 {
    --rotation: 0;
    position: absolute;
    bottom: 14%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.matchComparisonResPriceComparisonNeedle7 {
    --rotation: 0;
    position: absolute;
    bottom: 14%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.matchComparisonResPriceComparisonNeedle8 {
    --rotation: 0;
    position: absolute;
    bottom: 14%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}

/* INCOME GAUGE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
.matchComparisonIncomeComparisonPointer1 {
    position: absolute;
    --bottomIncome: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: bottom;
    z-index: 12;
    transform: translateX(calc(var(--bottomIncome) * 7.2px));
    width: 0;
	height: 0;
	border-left: 9.5px solid transparent;
	border-right: 9.5px solid transparent;
	border-top: 19px solid rgb(254, 39, 18);
}
.matchComparisonIncomeComparisonPointer2 {
    position: absolute;
    --bottomIncome: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: bottom;
    z-index: 12;
    transform: translateX(calc(var(--bottomIncome) * 7.15px));
    width: 0;
	height: 0;
	border-left: 9.25px solid transparent;
	border-right: 9.25px solid transparent;
	border-top: 18.5px solid rgb(102, 176, 50);
}
.matchComparisonIncomeComparisonPointer3 {
    position: absolute;
    --bottomIncome: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: bottom;
    z-index: 12;
    transform: translateX(calc(var(--bottomIncome) * 7.1px));
    width: 0;
	height: 0;
	border-left: 9px solid transparent;
	border-right: 9px solid transparent;
	border-top: 18px solid rgb(251, 153, 2);
}
.matchComparisonIncomeComparisonPointer4 {
    position: absolute;
    --bottomIncome: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: bottom;
    z-index: 12;
    transform: translateX(calc(var(--bottomIncome) * 7px));
    width: 0;
	height: 0;
	border-left: 8.75px solid transparent;
	border-right: 8.75px solid transparent;
	border-top: 17.5px solid rgb(52, 124, 152);
}
.matchComparisonIncomeComparisonPointer5 {
    position: absolute;
    --bottomIncome: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: bottom;
    z-index: 12;
    transform: translateX(calc(var(--bottomIncome) * 6.95px));
    width: 0;
	height: 0;
	border-left: 9.5px solid transparent;
	border-right: 9.5px solid transparent;
	border-top: 19px solid rgb(254, 240, 51);
}
.matchComparisonIncomeComparisonPointer6 {
    position: absolute;
    --bottomIncome: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: bottom;
    z-index: 12;
    transform: translateX(calc(var(--bottomIncome) * 6.9px));
    width: 0;
	height: 0;
	border-left: 9.25px solid transparent;
	border-right: 9.25px solid transparent;
	border-top: 18.5px solid rgb(128, 0, 0);
}
.matchComparisonIncomeComparisonPointer7 {
    position: absolute;
    --bottomIncome: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: bottom;
    z-index: 12;
    transform: translateX(calc(var(--bottomIncome) * 6.85px));
    width: 0;
	height: 0;
	border-left: 9px solid transparent;
	border-right: 9px solid transparent;
	border-top: 18px solid rgb(2, 71, 254);
}
.matchComparisonIncomeComparisonPointer8 {
    position: absolute;
    --bottomIncome: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: bottom;
    z-index: 12;
    transform: translateX(calc(var(--bottomIncome) * 6.8px));
    width: 0;
	height: 0;
	border-left: 8.75px solid transparent;
	border-right: 8.75px solid transparent;
	border-top: 17.5px solid rgb(134, 1, 175);
}
/* CHILD HOUSES GAUGE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
.matchComparisonChildHousesComparisonPointer1 {
    position: absolute;
    --bottomChdHouses: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomChdHouses) * 7.2px));
    width: 0;
	height: 0;
	border-left: 9.5px solid transparent;
	border-right: 9.5px solid transparent;
	border-top: 19px solid rgb(254, 39, 18);
}
.matchComparisonChildHousesComparisonPointer2 {
    position: absolute;
    --bottomChdHouses: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomChdHouses) * 7.15px));
    width: 0;
	height: 0;
	border-left: 9.25px solid transparent;
	border-right: 9.25px solid transparent;
	border-top: 18.5px solid rgb(102, 176, 50);
}
.matchComparisonChildHousesComparisonPointer3 {
    position: absolute;
    --bottomChdHouses: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomChdHouses) * 7.1px));
    width: 0;
	height: 0;
	border-left: 9px solid transparent;
	border-right: 9px solid transparent;
	border-top: 18px solid rgb(251, 153, 2);
}
.matchComparisonChildHousesComparisonPointer4 {
    position: absolute;
    --bottomChdHouses: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomChdHouses) * 7.05px));
    width: 0;
	height: 0;
	border-left: 8.75px solid transparent;
	border-right: 8.75px solid transparent;
	border-top: 17.5px solid rgb(52, 124, 152);
}
.matchComparisonChildHousesComparisonPointer5 {
    position: absolute;
    --bottomChdHouses: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomChdHouses) * 7px));
    width: 0;
	height: 0;
	border-left: 9.5px solid transparent;
	border-right: 9.5px solid transparent;
	border-top: 19px solid rgb(224, 254, 51);
}
.matchComparisonChildHousesComparisonPointer6 {
    position: absolute;
    --bottomChdHouses: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomChdHouses) * 6.95px));
    width: 0;
	height: 0;
	border-left: 9.25px solid transparent;
	border-right: 9.25px solid transparent;
	border-top: 18.5px solid rgb(128, 0, 0);
}
.matchComparisonChildHousesComparisonPointer7 {
    position: absolute;
    --bottomChdHouses: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomChdHouses) * 6.9px));
    width: 0;
	height: 0;
	border-left: 9px solid transparent;
	border-right: 9px solid transparent;
	border-top: 18px solid rgb(2, 71, 254);
}
.matchComparisonChildHousesComparisonPointer8 {
    position: absolute;
    --bottomChdHouses: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomChdHouses) * 6.85px));
    width: 0;
	height: 0;
	border-left: 8.75px solid transparent;
	border-right: 8.75px solid transparent;
	border-top: 17.5px solid rgb(134, 1, 175);
}

/* TOTAL BIZ DENSITY CHART COMPARISON AREA !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
.matchComparisonTotalBizAreaComparison {
    height: 200px;
    width: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.matchComparisonTotalBizAreaComparison::after {
    content: '';
    position: absolute;
    background-color: black;
    z-index: 10;
    width: 13px;
    height: 13px;
    bottom: 30%;
    left: 50%;
    transform: translate(-50%, -90%);
    border-radius: 50%;
}
.matchComparisonBizDensityComparisonNeedle1 {
    --rotation: 0;
    position: absolute;
    bottom: 40%;
    left: 50%;
    width: 8px;
    height: 30%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.matchComparisonBizDensityComparisonNeedle2 {
    --rotation: 0;
    position: absolute;
    bottom: 40%;
    left: 50%;
    width: 8px;
    height: 30%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.matchComparisonBizDensityComparisonNeedle3 {
    --rotation: 0;
    position: absolute;
    bottom: 40%;
    left: 50%;
    width: 8px;
    height: 30%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.matchComparisonBizDensityComparisonNeedle4 {
    --rotation: 0;
    position: absolute;
    bottom: 40%;
    left: 50%;
    width: 8px;
    height: 30%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.matchComparisonBizDensityComparisonNeedle5 {
    --rotation: 0;
    position: absolute;
    bottom: 40%;
    left: 50%;
    width: 8px;
    height: 30%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.matchComparisonBizDensityComparisonNeedle6 {
    --rotation: 0;
    position: absolute;
    bottom: 40%;
    left: 50%;
    width: 8px;
    height: 30%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.matchComparisonBizDensityComparisonNeedle7 {
    --rotation: 0;
    position: absolute;
    bottom: 40%;
    left: 50%;
    width: 8px;
    height: 30%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.matchComparisonBizDensityComparisonNeedle8 {
    --rotation: 0;
    position: absolute;
    bottom: 40%;
    left: 50%;
    width: 8px;
    height: 30%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.matchComparisonTotalBusinessNumberComparison {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    z-index: 12;
}
.matchComparisonLowDensityLabelComparison {
    position: absolute;
    bottom: 25%;
    left: -10%;
    text-align: center;
    z-index: 12;
    font-size: 10px;
    font-weight: bold;
}

.matchComparisonHighDensityLabelComparison {
    position: absolute;
    bottom: 25%;
    right: -10%;
    text-align: center;
    z-index: 12;
    font-size: 10px;
    font-weight: bold;
}
.matchComparisonTotalBusinessTextComparison {
    margin-left: 78px;
    width: 50%;
    text-align: center;
    z-index: 12;
    font-size: 14px;
    font-weight: bold;
}

/* HIGH-END BIZ GAUGE NEEDLES !!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
.matchComparisonBizPricesGaugeComparison {
    position: relative;
    padding-top: 10px;
}

.matchComparisonBizPricesGaugeComparison::after {
    content: '';
    position: absolute;
    background-color: black;
    z-index: 11;
    width: 10px;
    height: 10px;
    bottom: 2%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}
.matchComparisonBizPricesComparisonNeedle1 {
    --rotation: 0;
    position: absolute;
    bottom: 14%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.matchComparisonBizPricesComparisonNeedle2 {
    --rotation: 0;
    position: absolute;
    bottom: 14%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.matchComparisonBizPricesComparisonNeedle3 {
    --rotation: 0;
    position: absolute;
    bottom: 14%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.matchComparisonBizPricesComparisonNeedle4 {
    --rotation: 0;
    position: absolute;
    bottom: 14%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.matchComparisonBizPricesComparisonNeedle5 {
    --rotation: 0;
    position: absolute;
    bottom: 14%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.matchComparisonBizPricesComparisonNeedle6 {
    --rotation: 0;
    position: absolute;
    bottom: 14%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.matchComparisonBizPricesComparisonNeedle7 {
    --rotation: 0;
    position: absolute;
    bottom: 14%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.matchComparisonBizPricesComparisonNeedle8 {
    --rotation: 0;
    position: absolute;
    bottom: 14%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
