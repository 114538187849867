/*tbody td:nth-child(even) {
    background-color: rgba(14, 170, 100, 0.1);
}
  
tbody td:nth-child(odd) {
    background-color: #fff;
}

thead th:nth-child(even) {
    background-color: rgba(14, 170, 100, 0.1);
}
  
thead th:nth-child(odd) {
    background-color: #fff;
}*/

.categoryCol {
    width: 15%;
}

.compareListingsMapButton:hover {
    transform: scale(1.02) perspective(0px);
}


/* DAY TRAFFIC & NIGHTLIFE GAUGE AREA !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/ 
.dayTrafficComparisonGauge {
    --rotation: 90;
    position: absolute;
    bottom: 25%;
    left: 2%; 
    width: 15px;
    height: 350px;
    background: linear-gradient(to top, rgb(228, 114, 103), rgb(250, 204, 68), rgb(52, 181, 122));
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-2%) rotate(calc(var(--rotation) * 1deg));
}

.dayTrafficComparisonGaugeHigh {
    position: absolute;
    z-index: 12;
    bottom: 0%;
    left: 90%;
    font-size: 10px;
    font-weight: bold;
    color: #058f1c;
}

.dayTrafficComparisonGaugeLow {
    position: absolute;
    z-index: 11;
    bottom: 0%;
    left: 2%;
    font-size: 10px;
    font-weight: bold;
    color: #d80808;
}

.nightlifeComparisonGauge {
    --rotation: 90;
    position: absolute;
    bottom: 25%;
    left: 4%;
    width: 15px;
    height: 350px;
    background: linear-gradient(to top, rgb(228, 114, 103), rgb(250, 204, 68), rgb(52, 181, 122));
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-4%) rotate(calc(var(--rotation) * 1deg));
}

.nightlifeComparisonGaugeHigh {
    position: absolute;
    z-index: 12;
    bottom: 0%;
    left: 92%;
    font-size: 10px;
    font-weight: bold;
    color: #058f1c;
}

.nightlifeComparisonGaugeLow {
    position: absolute;
    z-index: 12;
    bottom: 0%;
    left: 4%;
    font-size: 10px;
    font-weight: bold;
    color: #d80808;
}



/* DAYTIME TRAFFIC POINTERS */
/* The gauge width is 350px and the listing's daytime traffic score is out of 100 so therefore the transformation is the score * 3.5px  */
.daytimeComparisonPointer1 {
    position: absolute;
    --bottomDay: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: bottom;
    z-index: 12;
    transform: translateX(calc(var(--bottomDay) * 3.5px));
    width: 0;
	height: 0;
	border-left: 9.5px solid transparent;
	border-right: 9.5px solid transparent;
	border-top: 19px solid rgb(52, 181, 122);
}
.daytimeComparisonPointer2 {
    position: absolute;
    --bottomDay: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: bottom;
    z-index: 12;
    transform: translateX(calc(var(--bottomDay) * 3.5px));
    width: 0;
	height: 0;
	border-left: 9.25px solid transparent;
	border-right: 9.25px solid transparent;
	border-top: 18.5px solid rgb(223, 82, 69);
}
.daytimeComparisonPointer3 {
    position: absolute;
    --bottomDay: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: bottom;
    z-index: 12;
    transform: translateX(calc(var(--bottomDay) * 3.5px));
    width: 0;
	height: 0;
	border-left: 9px solid transparent;
	border-right: 9px solid transparent;
	border-top: 18px solid rgb(250, 204, 68);
}
.daytimeComparisonPointer4 {
    position: absolute;
    --bottomDay: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: bottom;
    z-index: 12;
    transform: translateX(calc(var(--bottomDay) * 3.5px));
    width: 0;
	height: 0;
	border-left: 8.75px solid transparent;
	border-right: 8.75px solid transparent;
	border-top: 17.5px solid rgb(30, 144, 255);
}

/* NIGHTLIFE POINTERS */
/* The gauge width is 350px and the listing's nightlife score is out of 100 so therefore the transformation is the score * 3.5px  */
.nightlifeComparisonPointer1 {
    position: absolute;
    --bottomNight: 1;
    left: 4%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomNight) * 3.5px));
    width: 0;
	height: 0;
	border-left: 9.5px solid transparent;
	border-right: 9.5px solid transparent;
	border-top: 19px solid rgb(52, 181, 122);
}
.nightlifeComparisonPointer2 {
    position: absolute;
    --bottomNight: 1;
    left: 4%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomNight) * 3.5px));
    width: 0;
	height: 0;
	border-left: 9.25px solid transparent;
	border-right: 9.25px solid transparent;
	border-top: 18.5px solid rgb(223, 82, 69);
}
.nightlifeComparisonPointer3 {
    position: absolute;
    --bottomNight: 1;
    left: 4%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomNight) * 3.5px));
    width: 0;
	height: 0;
	border-left: 9px solid transparent;
	border-right: 9px solid transparent;
	border-top: 18px solid rgb(250, 204, 68);
}
.nightlifeComparisonPointer4 {
    position: absolute;
    --bottomNight: 1;
    left: 4%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomNight) * 3.5px));
    width: 0;
	height: 0;
	border-left: 8.75px solid transparent;
	border-right: 8.75px solid transparent;
	border-top: 17.5px solid rgb(30, 144, 255);
}

/* REVIEW COUNT COMPARISON AREA WITH GAUGE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
.reviewCountComparisonPointer1 {
    position: absolute;
    --bottomReview: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomReview) * 3.5px));
    width: 0;
	height: 0;
	border-left: 9.5px solid transparent;
	border-right: 9.5px solid transparent;
	border-top: 19px solid rgb(52, 181, 122);
}
.reviewCountComparisonBackup1 {
    position: absolute;
    --bottomReview: 1;
    left: 2%;
    bottom: 55%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomReview) * 3.5px));
    width: 5px;
	height: 5px;
	border-radius: 50%;
	background-color: rgb(52, 181, 122);
}
.reviewCountComparisonPointer2 {
    position: absolute;
    --bottomReview: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomReview) * 3.5px));
    width: 0;
	height: 0;
	border-left: 9.5px solid transparent;
	border-right: 9.5px solid transparent;
	border-top: 19px solid rgb(223, 82, 69);
}
.reviewCountComparisonBackup2 {
    position: absolute;
    --bottomReview: 1;
    left: 2.5%;
    bottom: 55%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomReview) * 3.5px));
    width: 13.5px;
	height: 13.5px;
	border-radius: 50%;
	background-color: rgb(223, 82, 69);
}
.reviewCountComparisonPointer3 {
    position: absolute;
    --bottomReview: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomReview) * 3.5px));
    width: 0;
	height: 0;
	border-left: 9.5px solid transparent;
	border-right: 9.5px solid transparent;
	border-top: 19px solid rgb(250, 204, 68);
}
.reviewCountComparisonBackup3 {
    position: absolute;
    --bottomReview: 1;
    left: 2%;
    bottom: 55%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomReview) * 3.5px));
    width: 5px;
	height: 5px;
	border-radius: 50%;
	background-color: rgb(250, 204, 68);
}
.reviewCountComparisonPointer4 {
    position: absolute;
    --bottomReview: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomReview) * 3.5px));
    width: 0;
	height: 0;
	border-left: 9.5px solid transparent;
	border-right: 9.5px solid transparent;
	border-top: 19px solid rgb(30, 144, 255);
}

/* RES PRICES COMPARISON GAUGES !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
.resPriceGaugeComparison {
    position: relative;
}

.resPriceGaugeComparison::after {
    content: '';
    position: absolute;
    background-color: black;
    z-index: 11;
    width: 10px;
    height: 10px;
    bottom: 2%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.resPriceComparisonNeedle1 {
    --rotation: 0;
    position: absolute;
    bottom: 14%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.resPriceComparisonNeedle2 {
    --rotation: 0;
    position: absolute;
    bottom: 14%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.resPriceComparisonNeedle3 {
    --rotation: 0;
    position: absolute;
    bottom: 14%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.resPriceComparisonNeedle4 {
    --rotation: 0;
    position: absolute;
    bottom: 14%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}

/* INCOME GAUGE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
.incomeComparisonPointer1 {
    position: absolute;
    --bottomIncome: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomIncome) * 3.5px));
    width: 0;
	height: 0;
	border-left: 9.5px solid transparent;
	border-right: 9.5px solid transparent;
	border-top: 19px solid rgb(52, 181, 122);
}
.incomeComparisonPointer2 {
    position: absolute;
    --bottomIncome: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomIncome) * 3.5px));
    width: 0;
	height: 0;
	border-left: 9.25px solid transparent;
	border-right: 9.25px solid transparent;
	border-top: 18.5px solid rgb(223, 82, 69);
}
.incomeComparisonPointer3 {
    position: absolute;
    --bottomIncome: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomIncome) * 3.5px));
    width: 0;
	height: 0;
	border-left: 9px solid transparent;
	border-right: 9px solid transparent;
	border-top: 18px solid rgb(250, 204, 68);
}
.incomeComparisonPointer4 {
    position: absolute;
    --bottomIncome: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomIncome) * 3.5px));
    width: 0;
	height: 0;
	border-left: 8.75px solid transparent;
	border-right: 8.75px solid transparent;
	border-top: 17.5px solid rgb(30, 144, 255);
}
/* CHILD HOUSES GAUGE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
.childHousesComparisonPointer1 {
    position: absolute;
    --bottomChdHouses: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomChdHouses) * 3.5px));
    width: 0;
	height: 0;
	border-left: 9.5px solid transparent;
	border-right: 9.5px solid transparent;
	border-top: 19px solid rgb(52, 181, 122);
}
.childHousesComparisonPointer2 {
    position: absolute;
    --bottomChdHouses: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomChdHouses) * 3.5px));
    width: 0;
	height: 0;
	border-left: 9.25px solid transparent;
	border-right: 9.25px solid transparent;
	border-top: 18.5px solid rgb(223, 82, 69);
}
.childHousesComparisonPointer3 {
    position: absolute;
    --bottomChdHouses: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomChdHouses) * 3.5px));
    width: 0;
	height: 0;
	border-left: 9px solid transparent;
	border-right: 9px solid transparent;
	border-top: 18px solid rgb(250, 204, 68);
}
.childHousesComparisonPointer4 {
    position: absolute;
    --bottomChdHouses: 1;
    left: 2%;
    bottom: 34%;
    transform-origin: center;
    z-index: 12;
    transform: translateX(calc(var(--bottomChdHouses) * 3.5px));
    width: 0;
	height: 0;
	border-left: 8.75px solid transparent;
	border-right: 8.75px solid transparent;
	border-top: 17.5px solid rgb(30, 144, 255);
}

/* TOTAL BIZ DENSITY CHART COMPARISON AREA !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
.totalBizAreaComparison {
    height: 200px;
    width: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.totalBizAreaComparison::after {
    content: '';
    position: absolute;
    background-color: black;
    z-index: 10;
    width: 13px;
    height: 13px;
    bottom: 30%;
    left: 50%;
    transform: translate(-50%, -90%);
    border-radius: 50%;
}
.bizDensityComparisonNeedle1 {
    --rotation: 0;
    position: absolute;
    bottom: 40%;
    left: 50%;
    width: 8px;
    height: 30%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.bizDensityComparisonNeedle2 {
    --rotation: 0;
    position: absolute;
    bottom: 40%;
    left: 50%;
    width: 8px;
    height: 30%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.bizDensityComparisonNeedle3 {
    --rotation: 0;
    position: absolute;
    bottom: 40%;
    left: 50%;
    width: 8px;
    height: 30%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.bizDensityComparisonNeedle4 {
    --rotation: 0;
    position: absolute;
    bottom: 40%;
    left: 50%;
    width: 8px;
    height: 30%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.totalBusinessNumberComparison {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    z-index: 12;
}
.lowDensityLabelComparison {
    position: absolute;
    bottom: 25%;
    left: -10%;
    text-align: center;
    z-index: 12;
    font-size: 10px;
    font-weight: bold;
}

.highDensityLabelComparison {
    position: absolute;
    bottom: 25%;
    right: -10%;
    text-align: center;
    z-index: 12;
    font-size: 10px;
    font-weight: bold;
}
.totalBusinessTextComparison {
    margin-left: 78px;
    width: 50%;
    text-align: center;
    z-index: 12;
    font-size: 14px;
    font-weight: bold;
}

/* HIGH-END BIZ GAUGE NEEDLES !!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
.bizPricesComparisonNeedle1 {
    --rotation: 0;
    position: absolute;
    bottom: 14%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.bizPricesComparisonNeedle2 {
    --rotation: 0;
    position: absolute;
    bottom: 14%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.bizPricesComparisonNeedle3 {
    --rotation: 0;
    position: absolute;
    bottom: 14%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}
.bizPricesComparisonNeedle4 {
    --rotation: 0;
    position: absolute;
    bottom: 14%;
    left: 50%;
    width: 8px;
    height: 45%;
    background-color: black;
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform-origin: bottom;
    z-index: 10;
    transform: translateX(-50%) rotate(calc(var(--rotation) * 1deg));
}

#compareBtnShake {
    animation: shake 0.5s;
    animation-iteration-count: 1;
}
  
  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }

  .jfdg {
      color: #ffa600
  }