.descContainer {
    background-color: white;
    transition: all .5s linear;
    padding-top: 50px;
}

.demoVidAreaContainer {
    background-color: rgba(14, 170, 100, 0.8);
    transition: all .5s linear;
    padding-top: 50px;
}

.photo {
    height: 150px;
    width: 140px;
    background-color: transparent;
}

.description {
    word-spacing: 2px;
    height: 800px;
}

.descPageAreaTitle {
    color: white;
    transition: all .5s linear;
}

.descPageAreaTitleBlack {
    color: black;
    transition: all .5s linear;
}

.perfectSpace {
    text-align: center;
    color: black;
    padding-top: 10px;
    word-spacing: 3px;
}

.perfectHeader {
    font-family: Arial, Helvetica, sans-serif;
}

.proprietaryDescription {
    width: 850px;
    margin-top: 50px;
    margin-left: 220px;
    margin-bottom: 50px;
    font-family: Arial, Helvetica, sans-serif;
}

.descriptionMap {
    opacity: .8;
    border: 1px solid white;
}

.descriptionGraphic {
    height: 90%;
    margin-left: auto;
    margin-right: auto;
}

#descPageLink {
    height: 50;
    font-size: 14;
    color: rgb(223, 82, 69);
    text-decoration: underline;
    text-underline-offset: 8;
    text-decoration-thickness: 2;
    outline: none;
    transition: all .5s linear;
}

#descPageLinkYellow {
    height: 50;
    font-size: 14;
    color: rgb(250, 204, 68);
    text-decoration: underline;
    text-underline-offset: 8;
    text-decoration-thickness: 2;
    outline: none;
    transition: all .5s linear;
}

#descStorefront {
    width: 100px;
    height: 100px;
    margin-top: 75px;
    color: rgba(52, 181, 122, .7);
    transition: all .5s linear;
}

#descStorefrontWhite {
    width: 100px;
    height: 100px;
    margin-top: 75px;
    color: white;
    transition: all .5s linear;
}

#descStoreMarker {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -125%);
    height: 50px;
    width: 50px;
    color: rgb(52, 181, 122);
    transition: all .5s linear;
}

#descStoreMarkerWhite {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -125%);
    height: 50px;
    width: 50px;
    color: white;
    transition: all .5s linear;
}

.descPageText {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    text-align: center;
    font-size: 18;
    font-family: Arial, Helvetica, sans-serif;
    transition: all .5s linear;
}

.descPageTextWhite {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    text-align: center;
    font-size: 18;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    transition: all .5s linear;
}

#descQuestion {
    width: 90px;
    height: 90px;
    margin-top: 70px;
    color: rgba(52, 181, 122, .7);
    transition: all .5s linear;
}

#descQuestionWhite {
    width: 90px;
    height: 90px;
    margin-top: 70px;
    color: white;
    transition: all .5s linear;
}

.trapezoid {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -91%);
    border-bottom: 110px solid rgba(52, 181, 122, .4);
    border-left: 55px solid transparent;
    border-right: 55px solid transparent;
    height: 0;
    width: 235px;
    margin-top: 60px;
}

.trapezoid1 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -100%);
    z-index: 11;
    border-bottom: 100px solid whitesmoke;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    height: 0;
    width: 220px;
    margin-top: 65px;
}

#trap1 {
    position: absolute;
    left: -0px;
    bottom: -20px;
    width: 50px;
    height: 50px;
    color: rgb(228, 114, 103);
}

#trap2 {
    position: absolute;
    left: 65%;
    bottom: -53px;
    width: 50px;
    height: 50px;
    color: rgb(52, 181, 122);
}

#trap3 {
    position: absolute;
    left: 20%;
    top: 40px;
    width: 50px;
    height: 50px;
    color: rgb(250, 204, 68);
}

#clientPic {
    max-height: 500px;
    max-width: 800px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#amdfStorefront {
    height: 500px;
    width: 800px;
}

#clientPicMobile {
    max-height: 80%;
    max-width: 90%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#amdfStorefrontMobile {
    height: 100%;
    width: 100%;
}

.clientQuoteBox {
    position: absolute;
    z-index: 11;
    top: 25%;
    transform: translate(0%, -20%);
    right: -10%;
    border: 10px solid rgba(52, 181, 122, .8);
    height: 450px;
    width: 90%;
}

.clientQuoteBoxMobile {
    z-index: 11;
    border: 10px solid rgba(52, 181, 122, .8);
    height: 100%;
    width: 90%;
}

.bottomGetStarted {
    width: 250px;
    height: 90%;
}

#mapCard {
    position: relative;
    height: 100%;
    width: 100%;
}

#laptop {
    position: absolute;
    bottom: 1%;
    left: 0%;
    height: 575px;
    width: 970px;
    z-index: 14;
}

#mapScreenshot {
    position: absolute;
    top: 8.25%;
    left: 15%;
    width: 881px;
    height: 486px;
}

.howItWorksTrapezoid {
    position: absolute;
    border-bottom: 110px solid rgba(52, 181, 122, .4);
    border-left: 55px solid transparent;
    border-right: 55px solid transparent;
    height: 0;
    width: 235px;
    margin-top: 50px;
    margin-left: 58px;
}

.howItWorksTrapezoid1 {
    position: absolute;
    z-index: 11;
    border-bottom: 100px solid whitesmoke;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    height: 0;
    width: 220px;
    margin-top: 55px;
    margin-left: 65px;
}

.howItWorksMarker::after {
    content: '';
    position: absolute;
    background-color: #eb2121cb;
    z-index: 12;
    width: 75px;
    height: 25px;
    bottom: 71.5%;
    left: 51.5%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

#markerScore {
    position: absolute;
    background-color: white;
    border: 1px solid green;
    padding-top: 3px;
    font-weight: bold;
    z-index: 12;
    width: 35px;
    height: 35px;
    bottom: 77.5%;
    left: 51%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    text-align: center;
}

#howItWorksCatScore {
    background-color: white;
    border: 2px solid rgb(52, 181, 122);
    margin-top: 15px;
    padding-top: 3.5px;
    padding-left: 7px;
    font-weight: bold;
    z-index: 12;
    width: 35px;
    height: 35px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

#methodBackground { 
    background-size: cover; 
    opacity: .4;
    height: 800px;
}

.roadmapCircle {
    height: 175px;
    width: 175px;
    border-radius: 50%;
    background-color: white;
    animation: fadeInAnimation ease 1s, loading ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    font-weight: bold;
}

#prodFeaturesBlock1 {
    position: absolute; 
    top: 25%;
    left: 0%;
    background-color: #9cfff2;
    width: 100px;
    height: 150px;
    border-radius: 25%;
    z-index: -1;
    animation: fadeInAnimation ease .5s, prodFeaturesLeftShift ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

#prodFeaturesBlock2 {
    position: absolute; 
    bottom: 15%;
    right: 1%;
    background-color: #9cffc2de;
    width: 100px;
    height: 150px;
    border-radius: 25%;
    z-index: -1;
    animation: fadeInAnimation ease .5s, prodFeaturesRightShift ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

#prodFeaturesBlock3 {
    position: absolute; 
    top: 25%;
    left: 0%;
    background-color: #9cfff2;
    width: 100px;
    height: 150px;
    border-radius: 25%;
    z-index: -1;
    animation: fadeOutAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

#prodFeaturesBlock4 {
    position: absolute; 
    bottom: 15%;
    right: 1%;
    background-color: #9cffc2de;
    width: 100px;
    height: 150px;
    border-radius: 25%;
    z-index: -1;
    animation: fadeOutAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

#prodFeaturesBlock5 {
    position: absolute; 
    top: 15%;
    left: 0%;
    background-color: #9cfff2;
    width: 90px;
    height: 300px;
    border-top-left-radius: 25%;
    border-bottom-left-radius: 25%;
    opacity: 0;
    z-index: -1;
    animation: fadeInAnimation ease .5s, prodFeaturesLeftShift ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 1000ms;
}

#prodFeaturesBlock6 {
    position: absolute; 
    bottom: 30%;
    right: 1%;
    background-color: #9cffc2de;
    width: 75px;
    height: 250px;
    opacity: 0;
    border-top-right-radius: 25%;
    border-bottom-right-radius: 25%;
    animation: fadeInAnimation ease .5s, prodFeaturesRightShift ease 3s;
    animation-delay: 2000ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    z-index: -1;
}

#prodFeaturesBlock7 {
    position: absolute; 
    top: -5%;
    left: 30%;
    background-color: #ff9cce;
    width: 175px;
    height: 50px;
    opacity: 0;
    border-top-right-radius: 25%;
    border-top-left-radius: 25%;
    animation: fadeInAnimation ease .5s, prodFeaturesRightShift ease 3s;
    animation-delay: 1500ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    z-index: -1;
}

#mapWhiteoutSquare {
    position: absolute; 
    bottom: 7%;
    left: 22.25%;
    transform: translate(-22.25%, -7%);
    background-color: white;
    width: 367.75px;
    height: 303.5px;
}

#filterboxWhiteoutSquare {
    position: absolute; 
    bottom: 7%;
    left: 65%;
    transform: translate(-22.25%, -7%);
    background-color: white;
    width: 241px;
    height: 303.5px;
}

#matchHomeWhiteoutSquare {
    position: absolute; 
    bottom: 12%;
    left: 30%;
    transform: translate(-22.25%, -7%);
    background-color: white;
    width: 600px;
    height: 215px;
    z-index: 1;
}

#prodFeaturesMapSquareCard {
    position: absolute; 
    top: 21%;
    left: 12%;
    width: 365px;
    height: 300px;
    animation: showcaseLeft ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#prodFeaturesScoreCard {
    position: absolute; 
    background-color: whitesmoke;
    top: 0%;
    left: 2.5%;
    width: 100px;
    height: 100px;
    border-radius: 25%;
    animation: showcaseLeft ease 1s, fadeInAnimation 1s ease;
    z-index: 1006;
    opacity: 0;
    animation-delay: 1000ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#prodFeaturesFilterboxSquareCard {
    position: absolute; 
    top: 38.75%;
    left: 64.5%;
    width: 240px;
    height: 310px;
    animation: showcaseRight ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#prodFeaturesMapSquareCard2 {
    position: absolute; 
    top: 21%;
    left: 12%;
    width: 365px;
    height: 300px;
    animation: returnRight ease 500ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

#prodFeaturesFilterboxSquareCard2 {
    position: absolute; 
    top: 21.5%;
    left: 58.5%;
    width: 240px;
    height: 310px;
    animation: returnLeft ease 500ms, fadeOutAnimation 100ms ease 500ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* MOBILE */
/**/

#prodFeaturesMapSquareCardMobile {
    position: absolute; 
    top: 21%;
    left: 12%;
    width: 60%;
    height: 70%;
    animation: showcaseLeft ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#prodFeaturesScoreCardMobile {
    position: absolute; 
    background-color: whitesmoke;
    top: 0%;
    left: 2.5%;
    width: 100px;
    height: 100px;
    border-radius: 25%;
    animation: showcaseLeft ease 1s, fadeInAnimation 1s ease;
    z-index: 1006;
    opacity: 0;
    animation-delay: 1000ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#prodFeaturesFilterboxSquareCardMobile {
    position: absolute; 
    top: 38.75%;
    left: 64.5%;
    width: 60%;
    height: 70%;
    animation: showcaseRight ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#prodFeaturesMapSquareCard2Mobile {
    position: absolute; 
    top: 21%;
    left: 12%;
    width: 60%;
    height: 70%;
    animation: returnRight ease 500ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

#prodFeaturesFilterboxSquareCard2Mobile {
    position: absolute; 
    top: 21.5%;
    left: 58.5%;
    width: 60%;
    height: 70%;
    animation: returnLeft ease 500ms, fadeOutAnimation 100ms ease 500ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* END MOBILE */
/**/

#prodFeaturesMatchHomepage {
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate(-49.5%, -46%); 
    max-height: 77%; 
    max-width: 77%;
    opacity: 0;
    animation: fadeInAnimation 100ms ease;
    animation-delay: 500ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

#prodFeaturesMatchHomepage2 {
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate(-49.5%, -46%); 
    max-height: 77%; 
    max-width: 77%;
    opacity: .5;
}

#prodFeaturesMatchBannerButton {
    position: absolute; 
    top: 18%;
    left: 60%;
    opacity: 0;
    width: 175px;
    height: 60px;
    animation: fadeInAnimation 1s ease, showcaseUp 1s ease 500ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 1500ms;
    z-index: 1000;
}

#matchBannerButtonGreenSquare {
    position: absolute; 
    top: 17%;
    left: 56%;
    transform: translate(-56%, -17%);
    background-color: whitesmoke;
    animation: fadeInAnimation 1s ease;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 1500ms;
    opacity: 0;
    border-radius: 5px;
    width: 75px;
    height: 20px;
    z-index: 1000;
}

#prodFeaturesPriceSizeCard {
    position: absolute; 
    top: 21%;
    left: 15%;
    width: 375px;
    height: 325px;
    animation: fadeInAnimation 500ms ease, showcaseLeft 500ms ease 500ms;
    opacity: 0;
    animation-delay: 500ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    z-index: 10;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#prodFeaturesTraffFiltsCard {
    position: absolute; 
    top: 65%;
    left: 62%;
    width: 275px;
    height: 225px;
    animation: fadeInAnimation 500ms ease, showcaseRight 500ms ease 1000ms;
    opacity: 0;
    animation-delay: 1000ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    z-index: 10;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.productMapFullScreen {
    animation: fadeInAnimation 1s ease;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    opacity: 0;
}

.prodFeaturesScoreChild {
    border-left: 1px solid black;
}

  
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}

@keyframes fadeOutAnimation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
     }
}

@keyframes loading {
    0% { transform: translateY(40%) }
    100% { transform: translateY(0%) }
}

@keyframes prodFeaturesLeftShift {
    0% { transform: translateX(40%) }
    100% { transform: translateX(0%) }
}

@keyframes prodFeaturesRightShift {
    0% { transform: translateX(-40%) }
    100% { transform: translateX(0%) }
}

@keyframes showcaseLeft {
    0% { transform: translate(0%) }
    100% { transform: translate(-50%, -40%) }
}

@keyframes showcaseRight {
    0% { transform: translate(0%) }
    100% { transform: translate(30%, -50%) scale(1.5) }
}

@keyframes showcaseUp {
    0% { transform: translate(0%) }
    100% { transform: translate(-50%, -125px) scale(1.5) }
}

@keyframes returnRight {
    0% { transform: translate(-50%, -40%) }
    100% { transform: translate(0%, 0%) }
}

@keyframes returnLeft {
    0% { transform: translate(30%, -50%) scale(1.5) }
    100% { transform: translate(0%, 0%) scale(1) }
}

