.nameGreeting {
    align-items: center;
}

.accountTypeArea {
    height: 175px;
    margin-top: 20px;
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    text-align: center;
}

.accountTypeDescArea {
    height: 125px;
    margin-top: 25px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
}

.accountTypeDesc {
    display: grid;
    grid-template-columns: 1fr 3fr;
    width: 80%;
}

.passwordForm {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    width: 75%;
}
